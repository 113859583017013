import React, { useEffect, useState, startTransition, useRef } from 'react';
import './Careers.css';
import { Backdrop, Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import CareersWelCome from '../../components/Careers/CareersWelCome/CareersWelcome';
import CareersOpenings from '../../components/Careers/CareersOpenings';
import JoinUsSection from '../../components/Careers/JoinUsSection';
import HowWeHire from '../../components/Careers/HowWeHire';
// import OurPeople from '../../components/Careers/OurPeople';
import Empowering from '../../components/Careers/Empowering';
import HomeIcon from '../../assets/common/breadcrumbs/home.svg';
import { useNavigate } from 'react-router-dom';

const Careers = () => {
  const navigate = useNavigate();
  const [timeLineSliderData, setTimeLineSliderData] = useState([]);
  const [vacanciesData, setVacanciesData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const currentOpeningsRef = useRef(null);

  const getTimeLineSliderApi = async () => {
    try {
      const timeLineSliderRes = await api.get(`${BASE_URL}${API_URL.TIMELINE_SLIDER}`);
      setIsLoader(true);
      const { data, message, status } = timeLineSliderRes;
      if (status) {
        startTransition(() => {
          setTimeLineSliderData(data);
        });
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getVacancyListApi = async () => {
    try {
      setIsLoader(true);
      const vacancyListRes = await api.get(`${BASE_URL}${API_URL.GET_VACANCY_LIST}`);
      const { data, message, status } = vacancyListRes;
      if (status) {
        const formattedData = data.map((item) => ({
          ...item,
          id: item.id?.$oid || item.id
        }));
        setVacanciesData(formattedData);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTimeLineSliderApi();
    getVacancyListApi();
  }, []);

  const scrollToOpenings = () => {
    currentOpeningsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.modal + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(2px)',
          display: 'flex',
          flexDirection: 'column'
        }}
        open={isLoader}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ marginLeft: 2, color: '#FFFFFF' }}>
          Fetching Vacancies...
        </Typography>
      </Backdrop>
      <Box className="career-breadcrumbs">
        <ul>
          <li>
            <IconButton onClick={() => navigate('/')} style={{ padding: 0 }}>
              <img src={HomeIcon} alt="homeicon" />
            </IconButton>
          </li>
          <li>
            <button className="no-pointer">
              <Typography fontFamily="gothamBold" color="textPrimary">
                Careers
              </Typography>
            </button>
          </li>
        </ul>
      </Box>
      <CareersWelCome
        timeLineSliderData={timeLineSliderData}
        isLoader={isLoader}
        onScrollToOpenings={scrollToOpenings}
      />
      <CareersOpenings ref={currentOpeningsRef} vacanciesData={vacanciesData} />
      <JoinUsSection />
      <HowWeHire />
      {/* <OurPeople /> */}
      <Empowering />
    </>
  );
};

export default Careers;
