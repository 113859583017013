export const FooterUsefulLinks = [
  {
    title: 'Terms and Condition',
    url: '/termsncondition'
  },
  // {
  //   title: 'Booking Procedure',
  //   url: 'https://beta.kesariselect.com'
  // },
  {
    title: 'Privacy Policy',
    url: '/privacy-policy'
  },
  {
    title: 'Careers',
    url: '/careers'
  },
  {
    title: 'Contact Details',
    url: '/contact-us'
  }
];
