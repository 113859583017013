import React, { useEffect, useRef, useState } from 'react';
import './JobDetails.css';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Typography,
  TextField
} from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { NoticePeriodOptions } from '../../../constants/jobDetailsConstant';
import { JobDetailsValidateForm } from '../../../Helper/FormValidation';
import { toast } from 'react-toastify';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import { api } from '../../../APIs/api';
import BackArrowIcon from '../../../assets/Images/BackArrow.svg';

const JobDetails = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const location = useLocation();
  const jobId = location?.state?.id;

  const [isLoader, setIsLoader] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Fetching Vacancy Details...');
  const [jobDetails, setJobDetails] = useState({});
  const [countryCode, setCountryCode] = useState('+91');

  const initialFormData = {
    fullName: '',
    phoneNumber: '',
    email: '',
    city: '',
    yearsOfExp: '',
    noticePeriod: '',
    currentCTC: '',
    expectedCTC: '',
    resume: null
  };

  const initialErrors = {
    fullName: '',
    phoneNumber: '+91',
    email: '',
    city: '',
    yearsOfExp: '',
    noticePeriod: '',
    currentCTC: '',
    expectedCTC: '',
    resume: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  const handleBackClick = () => navigate('/careers');

  const getJobDetailsApi = async () => {
    try {
      setIsLoader(true);
      setLoadingMessage('Fetching Vacancy Details...');
      const detailsResponse = await api.get(`${BASE_URL}${API_URL.GET_VACANCY_LIST}/${jobId}`);
      const { status, data, message } = detailsResponse;
      if (status) {
        if (Object.keys(data)?.length > 0) {
          const modifiedData = {
            ...data,
            id: data.id?.$oid || data.id
          };
          setJobDetails(modifiedData);
        } else {
          navigate(-1);
        }
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getJobDetailsApi();
  }, [jobId]);

  const EligibilityItems = jobDetails?.vacancy_qualification
    ? jobDetails.vacancy_qualification
        .split('.')
        .map((item) => item.trim())
        .filter((item) => item.length > 0)
    : [];

  const ResponsibilitiesItems = jobDetails?.vacancy_responsibilities
    ? jobDetails.vacancy_responsibilities
        .split('.')
        .map((item) => item.trim())
        .filter((item) => item.length > 0)
    : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handlePhoneChange = (value, country) => {
    setFormData((prev) => ({ ...prev, phoneNumber: value }));
    setErrors((prev) => ({ ...prev, phoneNumber: '' }));
    setCountryCode(country?.country?.dialCode);
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, resume: e.target.files[0] }));
    setErrors((prev) => ({ ...prev, resume: '' }));
  };

  const handleResetForm = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (JobDetailsValidateForm(formData, setErrors)) {
      setIsLoader(true);
      setLoadingMessage('Submitting Vacancy Details...');
      try {
        const payload = {
          full_name: formData.fullName,
          email: formData.email,
          country_code: `+${countryCode}`,
          phone: formData.phoneNumber.replace(`+${countryCode}`, ''),
          current_city: formData.city,
          year_of_exp: formData.yearsOfExp,
          notice_period: formData.noticePeriod,
          current_ctc: formData.currentCTC,
          expected_ctc: formData.expectedCTC,
          resume: formData.resume,
          vacancy_id: jobId,
          vacancy_name: jobDetails.vacancy_title
        };

        const saveVacancyRes = await api.post(`${BASE_URL}${API_URL.SAVE_VACANCY_FORM}`, payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        const { message, status } = saveVacancyRes;

        if (status) {
          toast.success(message, { autoClose: 3000 });
          handleResetForm();
          handleBackClick();
        } else {
          const errorMessage = Array.isArray(message) ? message.join(', ') : message;
          toast.error(errorMessage, { autoClose: 3000 });
        }
      } catch (error) {
        toast.error(error.message, { autoClose: 3000 });
      } finally {
        setIsLoader(false);
      }
    }
  };

  const renderError = (field) =>
    errors[field] && (
      <Typography color="error" fontSize="12px">
        {errors[field]}
      </Typography>
    );

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#27306B',
          minHeight: { xs: '25vh', md: '20vh' },
          p: { xs: 2, md: 4 }
        }}
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.modal + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(2px)',
          display: 'flex',
          flexDirection: 'column'
        }}
        open={isLoader}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ marginLeft: 2, color: '#FFFFFF' }}>
          {loadingMessage}
        </Typography>
      </Backdrop>
      <Grid
        container
        spacing={4}
        sx={{
          marginTop: { xs: '-220px', md: '-190px', lg: '-190px' },
          paddingLeft: { xs: '35px', md: '50px', lg: '96px' },
          paddingRight: { xs: '35px', md: '50px', lg: '96px' }
        }}>
        {/* Left Section */}
        <Grid item xs={12} md={6} lg={7} sx={{ pt: { xs: '65px !important' } }}>
          <Typography className="job-title">{jobDetails.vacancy_title}</Typography>
          <Typography className="job-desc" sx={{ marginBottom: { xs: 12.5, md: 6, lg: 6 } }}>
            {jobDetails.vacancy_department} / {jobDetails.vacancy_location} /{' '}
            {jobDetails.vacancy_type}
          </Typography>
          <Button
            onClick={handleBackClick}
            sx={{
              pl: 0,
              display: 'flex',
              alignItems: 'center',
              color: '#27306B',
              transition: 'color 0.2s ease-in-out, transform 0.2s ease-in-out',
              '&:hover': {
                color: '#1a234d'
              }
            }}>
            <Box
              component="img"
              src={BackArrowIcon}
              alt="Back Arrow"
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateX(-5px)'
                }
              }}
            />
            <Typography
              component="span"
              sx={{
                fontFamily: 'gothamBold',
                fontWeight: '700',
                textDecoration: 'underline',
                transition: 'text-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',
                '&:hover': {
                  textShadow: '1px 1px 3px rgba(39, 48, 107, 0.5)',
                  transform: 'scale(1.05)'
                }
              }}>
              Back
            </Typography>
          </Button>
          <hr
            className="job-details-hr-line"
            style={{
              width: '100%',
              border: '0.5px solid #00000012'
            }}
          />
          <Typography className="job-decs-heading">Eligibility</Typography>
          <ul className="job-decs-ui">
            {EligibilityItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <Typography className="job-decs-heading" sx={{ marginTop: '32px !important' }}>
            Responsibilities
          </Typography>
          <ul className="job-decs-ui">
            {ResponsibilitiesItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Grid>

        {/* Right Section - Form */}
        <Grid item xs={12} md={6} lg={5} sx={{ pb: { md: 3, lg: 3 } }}>
          <Paper className="job-details-form" elevation={3}>
            <Typography className="job-details-form-desc1" gutterBottom>
              We require your basic details to proceed with the application. This helps us evaluate
              your application.
            </Typography>
            <Typography className="job-details-form-desc2">All fields are mandatory</Typography>
            <TextField
              fullWidth
              label="Full Name*"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              margin="dense"
              error={!!errors.fullName}
              helperText={errors.fullName}
            />
            <PhoneInput
              defaultCountry="in"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              containerStyle={{ width: '100%' }}
              inputStyle={{
                width: '100%',
                height: '56px',
                borderRadius: '6px',
                backgroundColor: '#FFFFFF08',
                fontFamily: 'inherit',
                ':hover': {
                  border: '1px solid #fff'
                }
              }}
              buttonStyle={{
                borderRadius: '6px 0 0 6px',
                border: '1px solid #FFFFFF08',
                backgroundColor: '#FFFFFF08',
                color: '#fff',
                height: '46px'
              }}
            />
            <TextField
              fullWidth
              type="email"
              label="Email ID*"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="dense"
              error={!!errors.email}
              helperText={errors.email}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Current City*"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  margin="dense"
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Years of Exp.*"
                  name="yearsOfExp"
                  value={formData.yearsOfExp}
                  onChange={handleChange}
                  margin="dense"
                  error={!!errors.yearsOfExp}
                  helperText={errors.yearsOfExp}>
                  {[...Array(11).keys()].map((year) => (
                    <MenuItem key={year} value={year === 10 ? '10+' : year}>
                      {year === 10 ? '10+' : year}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              select
              label="Notice Period*"
              name="noticePeriod"
              value={formData.noticePeriod}
              onChange={handleChange}
              margin="dense"
              error={!!errors.noticePeriod}
              helperText={errors.noticePeriod}>
              {NoticePeriodOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Current CTC*"
                  name="currentCTC"
                  value={formData.currentCTC}
                  onChange={handleChange}
                  margin="dense"
                  error={!!errors.currentCTC}
                  helperText={errors.currentCTC}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Expected CTC*"
                  name="expectedCTC"
                  value={formData.expectedCTC}
                  onChange={handleChange}
                  margin="dense"
                  error={!!errors.expectedCTC}
                  helperText={errors.expectedCTC}
                />
              </Grid>
            </Grid>
            <Box mt={2} display="grid">
              <Typography variant="body1" mb={1}>
                Upload Your Resume*
              </Typography>
              <input
                type="file"
                accept=".jpg,.png,.doc,.pdf,.docx"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{
                  cursor: 'pointer'
                }}
              />
              {renderError('resume')}
              <Typography variant="caption" color="textSecondary" mt={1}>
                Only jpg, png, doc, pdf, docx allowed (Max size: 2MB)
              </Typography>
            </Box>

            <Box
              mt={3}
              display="flex"
              gap={2}
              justifyContent="center"
              flexDirection={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12}>
                <Button
                  className="btn-reset"
                  variant="outlined"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={handleResetForm}>
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="btn-submit"
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={handleSubmit}>
                  Submit Application
                </Button>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetails;
