/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SouthWaleHeader.css';
import { CloseRounded } from '@mui/icons-material';
import {
  ButtonBase,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { newSouthWaleMenuList } from '../../constants/header';
import KesariLogo from '../../assets/Home/Footer/Footer_logo-new.webp';
import vector_Call from '../../assets/Header/Vector.svg';
import Vector_Toggle from '../../assets/Header/Vector_Toggle.svg';
import SearchDestination from '../SouthWaleHeader/SearchDestination';
import LoginModal from '../../components/conversational/modal/LoginModal/LoginModal';
import SignupModal from '../../components/conversational/modal/SignupModal/SignUpModal';
import ForgotPasswordModal from '../../components/conversational/modal/ForgotPasswordModal/ForgotPasswordModal';
import ContactUsModal from './ContactUsViaCall';
import CurrencyDropdown from '../../components/CurrencyDropdown/CurrencyDropdown';
import Years40Logo from '../../assets/Images/40-years-logo.png';

const SouthWaleHeader = ({ customizedHolidayRef, videoGalleryRef, photoGalleryRef }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenSignIn, setIsOpenSignIn] = useState(false);
  const [isOpenSignup, setIsOpenSignup] = useState(false);
  const [isForgotPassOpen, setIsForgotPassOpen] = useState(false);
  const newSouthWaleMenuListRef = useRef(null);
  const open = Boolean(anchorEl);
  const [isCall, setIsCall] = useState(false);
  const isNewSouthWalesHeader = sessionStorage.getItem('isNewSouthWalesHeader');

  const handleOpen = () => setIsCall(true);
  const handleClose = () => setIsCall(false);

  const openSignup = () => setIsOpenSignup(true);
  const closeSignup = () => setIsOpenSignup(false);
  const openLogin = () => setIsOpenSignIn(true);
  const closeLogin = () => setIsOpenSignIn(false);
  const openForgotPass = () => {
    setIsForgotPassOpen(true);
    closeLogin();
  };
  const closeForgotPass = () => setIsForgotPassOpen(false);

  const switchForgotPassToLogin = () => {
    closeForgotPass();
    openLogin();
  };

  const switchToSignup = () => {
    closeLogin();
    openSignup();
  };

  const switchToLogin = () => {
    closeSignup();
    openLogin();
  };

  const handleMenuClick = (event, index) => {
    const selectedMenu = newSouthWaleMenuList[index].menu;
    sessionStorage.setItem('southWalesMenuIndex', selectedMenu);
    if (selectedMenu === 'Customized Holidays') {
      if (pathname !== '/destination/new-south-wales/packages') {
        navigate('/destination/new-south-wales/packages');
      } else {
        scrollToSection(selectedMenu);
      }
    } else if (selectedMenu === 'Video Gallery') {
      navigate('/destination/new-south-wales/video-gallery');
    } else if (selectedMenu === 'Photo Gallery') {
      navigate('/destination/new-south-wales/photo-gallery');
    }
    // Commented code for header menu scrolled component
    // if (pathname !== '/destination/new-south-wales') {
    //   navigate('/destination/new-south-wales');
    // } else {
    //   scrollToSection(selectedMenu);
    // }
    handleCloseMobileMenu();
  };

  const scrollToSection = (menu) => {
    let element = null;
    if (menu === 'Customized Holidays') {
      element = customizedHolidayRef?.current;
    }
    // else if (menu === 'Video Gallery') {
    //   element = videoGalleryRef?.current;
    // } else if (menu === 'Photo Gallery') {
    //   element = photoGalleryRef?.current;
    // }
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        window.scrollBy({ top: -120, left: 0, behavior: 'smooth' });
      }, 900);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMobileMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const selectedMenu = sessionStorage.getItem('southWalesMenuIndex');
    if (selectedMenu) {
      setTimeout(() => {
        scrollToSection(selectedMenu);
        sessionStorage.removeItem('southWalesMenuIndex');
      }, 500);
    }
  }, [pathname]);

  return (
    <Box className="header-container-kesari">
      {/* Mobile Menu */}
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#27306b !important',
          height: {
            xs: '70px',
            sm: '80px',
            md: '80px',
            lg: '80px',
            xl: '80px'
          }
        }}>
        <Grid container sx={{ height: { xs: '44px', sm: '44px' } }}>
          <Grid item xs={12} sx={{ display: 'display', justifyContent: 'center' }}>
            <Grid className="Vector_Toggle" sx={{ top: '22px' }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'drawer-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}>
                <img src={Vector_Toggle} alt="" />
              </IconButton>
              <Drawer
                anchor="left"
                open={open}
                onClose={handleCloseMobileMenu}
                PaperProps={{
                  sx: { width: '25ch', backgroundColor: '#27306B', color: '#FFFFFF' }
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px'
                  }}>
                  <IconButton
                    onClick={handleCloseMobileMenu}
                    sx={{
                      color: '#FFFFFF',
                      marginLeft: '5px'
                    }}
                    aria-label="close">
                    <CloseRounded />
                  </IconButton>
                </Box>

                <List>
                  {newSouthWaleMenuList.map((option, index) => (
                    <Box key={index}>
                      <ListItem>
                        <ButtonBase
                          onClick={(e) => handleMenuClick(e, index)}
                          sx={{
                            width: '100%',
                            color: '#FFFFFF',
                            '&:hover': {
                              backgroundColor: '#3E4A89'
                            }
                          }}>
                          <ListItemText
                            className="south-wales-mobile-menu-list"
                            primary={option.menu}
                            sx={{
                              '& .MuiListItemText-primary': {
                                fontFamily: 'gothamBook'
                              }
                            }}
                          />
                        </ButtonBase>
                      </ListItem>
                    </Box>
                  ))}
                </List>
                <Divider sx={{ my: 5, borderColor: 'grey.400', borderWidth: '1px' }} />
                <img
                  src={KesariLogo}
                  alt="mobil-menu-brand-logo"
                  onClick={() => {
                    if (isNewSouthWalesHeader) {
                      navigate('/destination/new-south-wales');
                      setAnchorEl(null);
                    }
                  }}
                  style={{ cursor: 'pointer', width: '90%', height: '15%', marginLeft: '12px' }}
                />
              </Drawer>
            </Grid>

            <Grid className="kesari" sx={{ cursor: 'pointer', zIndex: { lg: 6000 } }}>
              <img
                src={KesariLogo}
                alt="desktop-brand-logo"
                onClick={() => {
                  navigate('/destination/new-south-wales');
                }}
              />
            </Grid>
            <SearchDestination />
            <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>
              <Box
                className="south-wales-call-box"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                    md: 'flex'
                  },
                  gap: '35px',
                  color: 'white'
                }}>
                <Grid
                  onClick={() => {
                    handleOpen();
                  }}
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    color: 'white',
                    cursor: 'pointer',
                    alignItems: 'center',
                    zIndex: 6000
                  }}>
                  <img
                    src={vector_Call}
                    alt="vector_Call"
                    style={{ width: '16px', height: '14px' }}
                  />
                  <span className="call south-wales-desktop-header-items">Contact Us</span>
                </Grid>
                {isCall && <ContactUsModal open={handleOpen} handleClose={handleClose} />}
                <Grid
                  sx={{
                    display: 'flex',
                    color: 'white',
                    alignItems: 'center',
                    cursor: 'pointer',
                    position: 'relative',
                    zIndex: 6000
                  }}>
                  <CurrencyDropdown />
                </Grid>
              </Box>
              <Box
                className="south-wales-years-logo"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex'
                  }
                }}>
                <img src={Years40Logo} alt="40 Years Logo" width="90px" height="90px" />
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: 'white',
                alignItems: 'center'
              }}>
              <Box className="mobile-view">
                <Box className="login-button">
                  <CurrencyDropdown />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Desktop Menu */}
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          display: { xs: 'none', sm: 'flex', md: 'flex' },
          alignItems: 'center',
          backgroundColor: '#20285B !important',
          height: '45px'
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ul
              ref={newSouthWaleMenuListRef}
              className="dropdown_menu"
              style={{
                listStyleType: 'none',
                display: 'flex',
                color: 'white',
                padding: 0,
                margin: 0,
                gap: '132px',
                whiteSpace: 'nowrap'
              }}>
              {newSouthWaleMenuList.map((item, i) => (
                <li
                  key={i}
                  onClick={(event) => handleMenuClick(event, i)}
                  style={{
                    cursor: 'pointer',
                    padding: '10px 15px'
                  }}>
                  {item.menu}
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Box>

      <LoginModal
        open={isOpenSignIn}
        handleCloseLoginModal={closeLogin}
        handleSignup={switchToSignup}
        openForgotPassModal={openForgotPass}
      />
      <SignupModal open={isOpenSignup} handleClose={closeSignup} handleLogin={switchToLogin} />
      <ForgotPasswordModal
        open={isForgotPassOpen}
        handleClose={closeForgotPass}
        handleLogin={switchForgotPassToLogin}
      />
    </Box>
  );
};

export default SouthWaleHeader;
