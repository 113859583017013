export const JobDetailsValidateForm = (formData, setErrors) => {
  let valid = true;
  let newErrors = {};

  // Full Name Validation
  const fullNameRegex = /^[A-Za-z\s]+$/;
  if (!formData.fullName || !fullNameRegex.test(formData.fullName)) {
    newErrors.fullName = 'Full Name required';
    valid = false;
  }

  // Phone Number Validation
  if (
    !formData.phoneNumber ||
    formData.phoneNumber.length < 6 ||
    formData.phoneNumber.length > 15
  ) {
    newErrors.phoneNumber = 'Mobile number is required';
    valid = false;
  }

  // Email Validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!formData.email || !emailRegex.test(formData.email)) {
    newErrors.email = ' Email address is required';
    valid = false;
  }

  // City Validation
  const cityRegex = /^[A-Za-z\s]+$/;
  if (!formData.city || !cityRegex.test(formData.city)) {
    newErrors.city = ' Enter valid city name';
    valid = false;
  }

  // Years of Experience Validation
  if (!formData.yearsOfExp) {
    newErrors.yearsOfExp = 'Years of Exp is required';
    valid = false;
  }

  // Notice Period Validation
  if (!formData.noticePeriod) {
    newErrors.noticePeriod = ' Notice Period is required';
    valid = false;
  }

  // Current CTC Validation (alphanumeric and 0)
  const ctcRegex = /^\d+(\.\d{1,2})?$/;
  if (!formData.currentCTC || !ctcRegex.test(formData.currentCTC)) {
    newErrors.currentCTC = 'Current CTC is required';
    valid = false;
  }

  // Expected CTC Validation (alphanumeric and 0)
  if (!formData.expectedCTC || !ctcRegex.test(formData.expectedCTC)) {
    newErrors.expectedCTC = 'Expected CTC is required';
    valid = false;
  }

  // Resume Validation (file size and type)
  if (!formData.resume) {
    newErrors.resume = 'Resume is required';
    valid = false;
  } else {
    const file = formData.resume;
    const fileSize = file.size / 1024;
    if (fileSize >= 2048) {
      newErrors.resume = 'File size should be up to 2MB.';
      valid = false;
    }
  }
  setErrors(newErrors);
  return valid;
};
