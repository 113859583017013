import React from 'react';
import { Box, Typography } from '@mui/material';

const JoinUsSection = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      py: { xs: 3, sm: 4, md: 5 },
      px: { xs: 2, sm: 4, md: 6 },
      backgroundColor: '#FFFFFF',
      color: '#212221'
    }}>
    <Typography
      fontFamily="gothamBold"
      fontSize="28px"
      sx={{
        fontSize: { xs: '20px', sm: '24px', md: '28px' },
        mb: 1,
        maxWidth: '90%'
      }}>
      Passionate about travel but don’t see your ideal role listed here?
    </Typography>
    <Typography
      fontFamily="gothamBook"
      sx={{
        fontSize: { xs: '16px', sm: '20px', md: '22px' },
        mb: 2,
        maxWidth: '85%'
      }}>
      We’d love to hear what makes you unique!
    </Typography>
    <Typography
      fontFamily="gothamBook"
      sx={{
        fontSize: { xs: '14px', sm: '18px', md: '20px' },
        maxWidth: '80%'
      }}>
      Send your resume to <a href="mailto:hr1@kesari.in">hr1@kesari.in</a>, and our team will be in
      touch.
    </Typography>
  </Box>
);

export default JoinUsSection;
