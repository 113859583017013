export const EligibilityItems = [
  'Bachelor’s / Master’s Degree preferably in IT or computers',
  '3 - 5 years of experience in a similar profile',
  'Good knowledge of computer hardware, windows server and networking skills, Active Directory Administration, managing anti-virus products Active Directory'
];

export const ResponsibilitiesItems = [
  'Lead and guide the team to achieve sales targets through effective coaching and support.',
  'Monitor calls, provide constructive feedback, and identify areas for improvement.',
  'Ensure excellent customer service and efficient handling of holiday inquiries.',
  'Provide regular training on holiday products, process updates, and organizational developments.',
  'Maintain MIS and prepare reports to track team performance and sales metrics.',
  'Collaborate with management to implement strategies for achieving department goals.'
];

export const NoticePeriodOptions = [
  'Serving Notice Period',
  '15 Days or Less',
  '1 Month',
  '2 Months',
  '3 Months'
];
