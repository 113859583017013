import Place1 from '../assets/Images/southWales/south-wales-1.jpg';
import Place2 from '../assets/Images/southWales/south-wales-2.jpg';
import Place3 from '../assets/Images/southWales/south-wales-3.jpg';
import Place4 from '../assets/Images/southWales/south-wales-4.jpg';
import Place5 from '../assets/Images/southWales/south-wales-5.jpg';
import Place6 from '../assets/Images/southWales/south-wales-6.jpg';
import Place7 from '../assets/Images/southWales/south-wales-7.jpg';
import Place8 from '../assets/Images/southWales/south-wales-8.jpg';
import Place9 from '../assets/Images/southWales/south-wales-9.jpg';

export const PhotoData = [Place1, Place2, Place3, Place4, Place5, Place6, Place7, Place8, Place9];

export const VideoData = [
  {
    title: 'New South Wales Highlights',
    url: 'https://www.youtube.com/watch?v=MaEF1VJWJO8&ab_channel=KesariSelect'
  },
  {
    title: 'Sydney Family Fun Highlights',
    url: 'https://www.youtube.com/watch?v=3BzM7ev6EW0&ab_channel=KesariSelect'
  },
  {
    title: 'New South Wales Highlights- Part 2 ',
    url: 'https://www.youtube.com/watch?v=u2ZkorLFRyY&ab_channel=KesariSelect'
  },
  {
    title: 'Hunter Valley Highlights ',
    url: 'https://www.youtube.com/watch?v=Gn4UhRerU80'
  }
];
