// Default Meta Data
export const defaultMetaTitle = 'Custom Tours & Travel Services - KESARi Select';
export const defaultMetaDescription =
  'Explore tailored tours and travel packages with KESARi Select. Experience personalized travel solutions for your dream destinations.';
export const defaultMetaKeywords = [
  'custom tours',
  'travel services',
  'tailored travel',
  'personalized tours',
  'KESARi Select',
  'travel packages',
  'dream destinations',
  'client requirements'
];

export const getTopDestinationContinent = 'Europe'; // Default  continent for package-listing page

// Static months list
export const MonthTabList = [
  { title: 'Jan', value: '1' },
  { title: 'Feb', value: '2' },
  { title: 'Mar', value: '3' },
  { title: 'Apr', value: '4' },
  { title: 'May', value: '5' },
  { title: 'Jun', value: '6' },
  { title: 'Jul', value: '7' },
  { title: 'Aug', value: '8' },
  { title: 'Sept', value: '9' },
  { title: 'Oct', value: '10' },
  { title: 'Nov', value: '11' },
  { title: 'Dec', value: '12' }
];

// Capital 1st letter of every word after the space
export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeFirstLetterWithAnd = (string) => {
  if (!string) return '';
  return string
    .toLowerCase()
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) => (word === 'and' ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join(' ');
};

// Calculate Business counting
export const formatCount = (count, desc) => {
  const formatWithSuffix = (number, suffix) => {
    if (suffix === 'B') {
      const formatted = number.toFixed(2);
      if (formatted.endsWith('0')) {
        return number.toFixed(1) + suffix;
      }
      return formatted + suffix;
    }
    return Math.floor(number) + suffix;
  };

  if (
    desc === 'Happy Customers' ||
    desc === 'Happy Travellers' ||
    desc === 'Locations Covered' ||
    desc === 'Tours Organized'
  ) {
    if (count >= 1000000000) {
      return formatWithSuffix(count / 1000000000, 'cr');
    } else if (count >= 1000000) {
      return formatWithSuffix(count / 1000000, 'la');
    } else if (count >= 1000) {
      return formatWithSuffix(count / 1000, 'k');
    }
  } else if (desc === 'Repeat Customers' || desc === 'Repeat Travellers') {
    return count + '%';
  }
  return count;
};

// Convert Array to Array of Objects
export const convertArrayToObjectArray = (array) =>
  array.map((item) => ({ label: item, value: item }));

// Header menu Trasformer helper funtions
export const transformDomasticToMobileDivision = (domastic) => {
  if (!domastic) {
    return [];
  }
  return Object.keys(domastic)
    .sort()
    .map((zoneKey) => {
      const states = Object.keys(domastic[zoneKey])
        .sort()
        .map((stateKey) => {
          const places = domastic[zoneKey][stateKey] || [];
          const isCity = places.length > 0 && places[0].Place !== null;
          return {
            title: stateKey,
            isCity,
            cities: isCity
              ? places
                  .map((place) => ({ title: String(place.Place) }))
                  .sort((a, b) => a.title.localeCompare(b.title))
              : []
          };
        });
      return {
        zone: `${zoneKey} India`,
        states
      };
    });
};

export const transformInternationalToMobileDivision = (international) => {
  if (!international) {
    return [];
  }
  return Object.keys(international)
    .sort()
    .map((continentKey) => {
      const countries = Object.keys(international[continentKey])
        .sort()
        .map((countryKey) => ({
          country: countryKey,
          countryContinent: international[continentKey][countryKey].continent
        }));
      return {
        continent: continentKey,
        countries
      };
    });
};

export const IndianZones = {
  West: [
    'Rajasthan',
    'Madhya Pradesh',
    'Gujarat',
    'Daman & Diu',
    'Dadra & Nagar Haveli',
    'Maharashtra',
    'Goa'
  ],
  South: [
    'Andhra Pradesh',
    'Karnataka',
    'Lakshadweep',
    'Kerala',
    'Tamil Nadu',
    'Pondicherry',
    'Telangana',
    'Andaman & Nicobar'
  ],
  North: [
    'Jammu & Kashmir',
    'Himachal Pradesh',
    'Punjab',
    'Chandigarh',
    'Uttarakhand',
    'Haryana',
    'Delhi',
    'Uttar Pradesh',
    'Leh & Ladakh'
  ],
  East: [
    'Bihar',
    'Sikkim',
    'Arunachal Pradesh',
    'Nagaland',
    'Manipur',
    'Mizoram',
    'Tripura',
    'Meghalaya',
    'Assam',
    'West Bengal',
    'Jharkhand',
    'Odisha',
    'Chhattisgarh',
    'North East'
  ]
};

// Find out zone using indian states
export const determineZone = (state) => {
  const normalizeState = (str) =>
    str
      .toLowerCase()
      .replace(/\band\b/g, '&')
      .replace(/\bislands\b/g, '')
      .replace(/\s+/g, ' ')
      .trim();
  const normalizedState = normalizeState(state);
  let zone = 'world';
  for (const [key, states] of Object.entries(IndianZones)) {
    const normalizedStates = states.map(normalizeState);
    if (normalizedStates.includes(normalizedState)) {
      zone = `${key}-india`.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-');
      break;
    }
  }
  return zone;
};

export const convertToHyphenatedLowerCase = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.toLowerCase().replace(/&/g, '-and-').replace(/\s+/g, '-');
};

// Check the Zone title whether India or World
export const checkZoneTitle = (zoneTitle) => {
  const indiaZones = ['north india', 'south india', 'east india', 'west india'];
  const lowerCaseZoneTitle = zoneTitle.toLowerCase();
  const isIndia = indiaZones.some((zone) => lowerCaseZoneTitle.includes(zone));
  return isIndia ? 'india' : 'world';
};

export const ConvertToRegionName = (hyphenatedString) => {
  const [region] = hyphenatedString.split('-');
  return region.charAt(0).toUpperCase() + region.slice(1);
};

// Convert selected theme as per dynamic url requirement
export const convertCommaToAnd = (input) =>
  input
    .split(',')
    .map((part) => part.trim().replace(/\s+/g, '-').toLowerCase())
    .join('-and-');

// Convert Theme data from path segments
export const formatThemePathSegmentsString = (input) => {
  const formatted = input?.replace(/-and-/g, ', ').replace(/-/g, ' ').toLowerCase();
  const capitalized = formatted?.replace(/\b\w/g, (char) => char.toUpperCase());
  return capitalized;
};

// Use url string after .com
export const getUrlAfterDomain = (url) => {
  const domainIndex = url.indexOf('.com');
  if (domainIndex !== -1) {
    return url.substring(domainIndex + 4);
  }
  return url;
};

//Check continent last character
export const formatContinentName = (continentName) => {
  if (continentName.endsWith('a')) {
    return continentName + 'n';
  } else {
    return continentName + 'an';
  }
};

// This helper function format prices for thousands, lakhs, and crores correctly.
export const formatPrice = (price) => {
  const priceString = price.toString();
  const lastThreeDigits = priceString.slice(-3);
  const otherDigits = priceString.slice(0, -3);
  const formattedPrice = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThreeDigits;
  return otherDigits ? formattedPrice : lastThreeDigits;
};

// Function to convert selectedBudgets from strings to numbers
export const ConvertSelectedBudgetsToNumbers = (selectedBudgets) => {
  const numericBudgets = [];
  selectedBudgets.forEach((range) => {
    let min = 0,
      max = Infinity;
    const cleanedRange = String(range)
      .replace(/[^\d.,-]/g, '')
      .split('-')
      .map((val) => {
        val = val.replace(/^\.*/, '');
        if (val.includes(',')) {
          return parseFloat(val.replace(/,/g, ''));
        } else {
          return parseFloat(val) * 100000;
        }
      });

    if (cleanedRange.length === 1) {
      if (range.includes('Below')) {
        max = cleanedRange[0];
      } else if (range.includes('Above')) {
        min = cleanedRange[0];
      }
    } else if (cleanedRange.length === 2) {
      [min, max] =
        cleanedRange[0] > cleanedRange[1] ? [cleanedRange[1], cleanedRange[0]] : cleanedRange;
    }
    numericBudgets.push({ min, max });
  });
  return numericBudgets;
};

export const cleanUrlString = (str) =>
  str
    .replace(/[^a-zA-Z0-9]+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+|-+$/g, '');
