import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

const QueenslandBlogCardSkeleton = () => (
  <Box
    mb={3}
    width={{ xs: '96%', md: '92%' }}
    boxShadow="10px 10px 30px 0px #37444A29"
    backgroundColor="#FFFFFF"
    borderRadius="10px"
    overflow="hidden">
    <Box width="100%" height="170px" position="relative">
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>

    <Box px={{ xs: 2.5, md: '12px' }} pb={0} pt={{ xs: '18px', md: '8px' }}>
      <Typography fontFamily="gothamBold" fontSize={{ xs: 14, md: 18 }} textAlign="center">
        <Skeleton variant="text" width="80%" height={{ xs: 44, md: 64 }} />
      </Typography>
    </Box>

    <Box bgcolor="#f9f9fb" pt={0} pr={3} pb={3} pl={3} minWidth="168px">
      <Skeleton variant="text" width="100%" height={30} />
      <Skeleton variant="text" width="100%" height={30} />
      <Skeleton variant="text" width="100%" height={30} />
      <Skeleton variant="text" width="100%" height={30} />

      {/* Skeleton for Read More Button */}
      <Box className="btn-container" mt="20px" display="flex" justifyContent="center" width="100%">
        <Skeleton variant="rectangular" width="120px" height="46px" borderRadius="10px" />
      </Box>
    </Box>
  </Box>
);

export default QueenslandBlogCardSkeleton;
