import React, { useEffect, useRef, useState, startTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDataList } from '../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../APIs/metaDataSlug';
import { API_URL, BASE_URL } from '../APIs/endpoint';
import { api } from '../APIs/api';
import { Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
// import Hero from '../components/home/Hero/Hero';
// import Counting from '../components/home/Counting/Counting';
// import SliderComponent from '../components/home/Slider/Slider';
// import TravelLife from '../components/home/TravelLife/TravelLife';
// import DiscoverGems from '../components/home/DiscoverGems/DiscoverGems';
// import QuickEnquiryModal from '../components/home/Hero/QuickEnquiryModal';
// import HeroMessage from '../assets/Home/hero_message.png';
import HomeSliderComponent from '../components/home/HomeSlider/HomeSlider';
import EveryStep from '../components/home/EveryStep/EveryStep';
import ThemedEscapes from '../components/home/ThemedEscapes/ThemedEscapes';
import PerfectWeekend from '../components/home/PerfectWeekend/PerfectWeekend';
import TailoredAdventures from '../components/home/TailoredAdventure/TailoredAdventures';
import ReadyToBook from '../components/home/ReadyToBook/ReadyToBook';
import ReviewCard from '../components/home/ReviewCommonCard/ReviewCommonCard';
import Header from '../layout/Header/Header';
import HelmetComponent from '../components/MetaDataComponent/Helmet';
import WhyKesariSelect from '../components/home/WhyKesariSelect/WhyKesariSelect';
import Travclan from '../components/home/Travclan/Travclan';

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};
const MotionBox = motion.create(Box);

const Home = () => {
  const observer = useRef();
  const dispatch = useDispatch();
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  // const [ratingCount, setRatingCount] = useState([]);
  const [timeLineSliderData, setTimeLineSliderData] = useState([]);
  // const [businessCount, setBusinessCount] = useState([]);
  const [whyKesariData, setWhyKesariData] = useState([]);
  // const [hiddenGemsData, setHiddenGemsData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  // const [worldWiseData, setWorldWiseData] = useState([]);
  const [themeData, setThemeData] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [travclanPackageListData, setTravclanPackageListData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');

  // const [openQuickEnquiry, setOpenQuickEnquiry] = useState(false);
  // const handleQuickEnquiryOpen = () => setOpenQuickEnquiry(true);
  // const handleQuickEnquiryClose = () => setOpenQuickEnquiry(false);

  const discoverGemsRef = useRef(null);
  const themedEscapesRef = useRef(null);
  const readyToBookRef = useRef(null);

  // START: Get & Set the choose currency from session storage
  const updateSelectedCurrency = () => {
    const currency = sessionStorage.getItem('selectedCurrency');
    if (currency) setSelectedCurrency(currency);
  };

  useEffect(() => {
    updateSelectedCurrency();
    const interval = setInterval(updateSelectedCurrency, 1000);
    return () => clearInterval(interval);
  }, []);
  // END:  Get & Set the choose currency from session storage

  const [sectionVisibility, setSectionVisibility] = useState({
    whyKesari: false,
    travclan: false,
    everyStep: false,
    themedEscapes: false,
    perfectWeekend: false,
    tailoredAdventures: false,
    reviewCard: false,
    readyToBook: false
  });

  // const getRatingCountsApi = async () => {
  //   setIsLoader(true);
  //   try {
  //     const ratingCountsRes = await api.get(`${BASE_URL}${API_URL.RATING_COUNT}`);
  //     const { data, message, status } = ratingCountsRes;
  //     if (status) {
  //       setRatingCount(data);
  //     } else {
  //       toast.error(message, { autoClose: 3000 });
  //     }
  //   } catch (err) {
  //     toast.error(err.message, { autoClose: 3000 });
  //   } finally {
  //     setIsLoader(false);
  //   }
  // };

  // const getBusinessCountsAPi = async () => {
  //   try {
  //     setIsLoader(true);
  //     const businessCountRes = await api.get(`${BASE_URL}${API_URL.BUSINESS_COUNT}`);
  //     const { data, message, status } = businessCountRes;
  //     if (status) {
  //       setBusinessCount(data);
  //     } else {
  //       toast.error(message, { autoClose: 3000 });
  //     }
  //   } catch (err) {
  //     toast.error(err.message, { autoClose: 3000 });
  //   } finally {
  //    setIsLoader(false);
  //  }
  // };

  const getWhyKesariSelectAPi = async () => {
    try {
      const WhyKesariSelectRes = await api.get(`${BASE_URL}${API_URL.WHY_KESARI_SELECT}`);
      setIsLoader(true);
      const { data, message, status } = WhyKesariSelectRes;
      if (status) {
        setWhyKesariData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getTravclanPackageListApi = async () => {
    try {
      setIsLoader(true);
      const travclanListRes = await api.get(`${BASE_URL}${API_URL.GET_TRAVCLAN_PACKAGE_LIST}`);
      const { data, message, status } = travclanListRes;
      if (status) {
        setTravclanPackageListData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getTimeLineSliderApi = async () => {
    try {
      const timeLineSliderRes = await api.get(`${BASE_URL}${API_URL.TIMELINE_SLIDER}`);
      setIsLoader(true);
      const { data, message, status } = timeLineSliderRes;
      if (status) {
        startTransition(() => {
          setTimeLineSliderData(data);
        });
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  // const getHiddenGemsApi = async () => {
  //   try {
  //     const hiddenGemsRes = await api.get(`${BASE_URL}${API_URL.HIDDEN_GEMS}`);
  //     setIsLoader(true);
  //     const { data, message, status } = hiddenGemsRes;
  //     if (status) {
  //       setHiddenGemsData(data);
  //     } else {
  //       toast.error(message, { autoClose: 3000 });
  //     }
  //   } catch (err) {
  //     toast.error(err.message, { autoClose: 3000 });
  //   } finally {
  //     setIsLoader(false);
  //   }
  // };

  const getReviewListApi = async () => {
    try {
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      setIsLoader(true);
      const { data, message, status } = reviewRes;
      if (status) {
        setReviewData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  // const getWorldWisePlacesApi = async () => {
  //   try {
  //     const worldWisePlacesRes = await api.get(`${BASE_URL}${API_URL.WORLD_WISE_PLACES}`);
  //     setIsLoader(true);
  //     const { data, message, status } = worldWisePlacesRes;
  //     if (status) {
  //       setWorldWiseData(data);
  //     } else {
  //       toast.error(message, { autoClose: 3000 });
  //     }
  //   } catch (err) {
  //     toast.error(err.message, { autoClose: 3000 });
  //   } finally {
  //     setIsLoader(false);
  //   }
  // };

  const getThemeListApi = async () => {
    try {
      const themeRes = await api.get(`${BASE_URL}${API_URL.THEME_LIST}`);
      setIsLoader(true);
      const { data, message, status } = themeRes;
      if (status) {
        setThemeData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getReadyToBookTourListApi = async () => {
    try {
      setIsLoader(true);
      const readyBookTourRes = await api.get(`${BASE_URL}${API_URL.READY_TO_BOOK_TOUR_LIST}`);
      const { data, message, status } = readyBookTourRes;
      if (status) {
        setTourData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem('isNewSouthWalesHeader', false);
    sessionStorage.setItem('isQueenslandHeader', false);
    sessionStorage.setItem('tripDetails', JSON.stringify({}));
    sessionStorage.setItem('currentPage', 1);
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.HOME_META_SLUG })).finally(() =>
      setIsLoader(false)
    );
    getTravclanPackageListApi();
    getTimeLineSliderApi();
    // getRatingCountsApi();
    // getBusinessCountsAPi();
    getWhyKesariSelectAPi();
    // getWorldWisePlacesApi();
    // getHiddenGemsApi();
    getReviewListApi();
    getThemeListApi();
    getReadyToBookTourListApi();
  }, [dispatch]);

  useEffect(() => {
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startTransition(() => {
            setSectionVisibility((prev) => ({
              ...prev,
              [entry.target.id]: true
            }));
          });
          observer.current.unobserve(entry.target);
        }
      });
    };

    observer.current = new IntersectionObserver(handleIntersect, options);
    const sections = [
      'whyKesari',
      'travclan',
      'everyStep',
      'themedEscapes',
      'perfectWeekend',
      'tailoredAdventures',
      'reviewCard',
      'readyToBook'
    ];
    sections.forEach((section) => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        observer.current.observe(sectionElement);
      }
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      {/* <PageLoadModal open={modalOpen} handleClose={handleModalClose} /> */}
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      <Header
        discoverGemsRef={discoverGemsRef}
        themedEscapesRef={themedEscapesRef}
        readyToBookRef={readyToBookRef}
      />

      <HomeSliderComponent timeLineSliderData={timeLineSliderData} isLoader={isLoader} />
      {/* <Hero ratingCount={ratingCount} error={error} /> */}

      <MotionBox
        id="whyKesari"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.whyKesari ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <WhyKesariSelect isLoader={isLoader} whyKesariData={whyKesariData} />
      </MotionBox>

      <MotionBox
        id="travclan"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.travclan ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <Travclan
          isLoader={isLoader}
          travclanPackages={travclanPackageListData}
          selectedCurrency={selectedCurrency}
        />
      </MotionBox>

      <MotionBox
        id="everyStep"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.everyStep ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <EveryStep />
      </MotionBox>

      <MotionBox
        id="themedEscapes"
        initial={{ opacity: 0, translateY: 0 }}
        animate={sectionVisibility.themedEscapes ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <ThemedEscapes themeData={themeData} isLoader={isLoader} ref={themedEscapesRef} />
      </MotionBox>

      <MotionBox
        id="perfectWeekend"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.perfectWeekend ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <PerfectWeekend />
      </MotionBox>

      <MotionBox
        id="tailoredAdventures"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.tailoredAdventures ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <TailoredAdventures />
      </MotionBox>

      <MotionBox
        id="reviewCard"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.reviewCard ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <ReviewCard isLoader={isLoader} reviewData={reviewData} />
      </MotionBox>

      <MotionBox
        id="readyToBook"
        initial={{ opacity: 0, translateY: 50 }}
        animate={sectionVisibility.readyToBook ? { opacity: 1, translateY: 0 } : {}}
        transition={{ duration: 0.2 }}>
        <ReadyToBook tourData={tourData} isLoader={isLoader} ref={readyToBookRef} />
      </MotionBox>
      {/* <Counting businessCount={businessCount} isLoader={isLoader} /> */}
      {/* <SliderComponent timeLineSliderData={timeLineSliderData} /> */}
      {/* <TravelLife worldWiseData={worldWiseData} isLoader={isLoader} /> */}
      {/* <DiscoverGems hiddenGemsData={hiddenGemsData} isLoader={isLoader} ref={discoverGemsRef} /> */}
      {/* <Box className="hero_message" onClick={handleQuickEnquiryOpen}>
        <img src={HeroMessage} alt="hero_message" className="hero-message-img" />
      </Box>
      <QuickEnquiryModal open={openQuickEnquiry} handleClose={handleQuickEnquiryClose} /> */}
    </>
  );
};

export default Home;
