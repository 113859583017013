import Image1 from '../assets/Images/img1.webp';
import Image2 from '../assets/Images/img2.webp';
import Image3 from '../assets/Images/img3.webp';
import Image4 from '../assets/Images/img4.webp';
import SoloImg from '../assets/Images/solo.webp';
import CoupleImg from '../assets/Images/Couple.jpg';
import FriendsImg from '../assets/Images/Friends.jpg';
import FamilyImg from '../assets/Images/Family.jpg';
import Place1Img from '../assets/Images/place1.webp';
import Place2Img from '../assets/Images/place2.webp';
import Place3Img from '../assets/Images/place3.webp';
import Place4Img from '../assets/Images/place4.webp';
import Place5Img from '../assets/Images/place5.webp';
import Place6Img from '../assets/Images/place6.webp';
import { WbSunnyRounded, CloudCircleRounded, Cloud } from '@mui/icons-material';

export const holidayDurations = [
  { days: '3-5 Days', image: Image1 },
  { days: '6-8 Days', image: Image2 },
  { days: '9-11 Days', image: Image3 },
  { days: '12-15 Days', image: Image4 }
];

export const holidayGroup = [
  { group: 'Solo', image: SoloImg },
  { group: 'Couple', image: CoupleImg },
  { group: 'Friends', image: FriendsImg },
  { group: 'Family', image: FamilyImg }
];

export const placesToExplore = [
  { name: 'Nusa Dua', image: Place1Img },
  { name: 'Seminyak', image: Place2Img },
  { name: 'Legian', image: Place3Img },
  { name: 'Jimbaran', image: Place4Img },
  { name: 'Sanur', image: Place5Img },
  { name: 'Ubud', image: Place6Img }
];

export const MonthsWether = [
  {
    name: 'January',
    temperature: '28°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    ),
    seasonal: true
  },
  {
    name: 'February',
    temperature: '26°C',
    icon: (
      <CloudCircleRounded
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'March',
    temperature: '27°C',
    icon: (
      <CloudCircleRounded
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    ),
    seasonal: true
  },
  {
    name: 'April',
    temperature: '30°C',
    icon: (
      <WbSunnyRounded
        sx={{
          color: 'yellow',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    ),
    seasonal: true
  },
  {
    name: 'May',
    temperature: '30°C',
    icon: (
      <WbSunnyRounded
        sx={{
          color: 'yellow',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'June',
    temperature: '26°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'July',
    temperature: '25°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'August',
    temperature: '27°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    ),
    seasonal: true
  },
  {
    name: 'September',
    temperature: '30°C',
    icon: (
      <WbSunnyRounded
        sx={{
          color: 'yellow',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'October',
    temperature: '25°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'November',
    temperature: '25°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    )
  },
  {
    name: 'December',
    temperature: '28°C',
    icon: (
      <Cloud
        sx={{
          color: 'skyblue',
          width: '43.24px',
          height: '38px',
          top: '361px',
          left: '424px',
          gap: '0px',
          opacity: '0px'
        }}
      />
    ),
    seasonal: true
  }
];
