import React, { useEffect, useState } from 'react';
import './SelectedVacationSpot.css';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded, SearchRounded } from '@mui/icons-material';
import moment from 'moment';
import CustomizeTripModal from '../modal/CustomizeTripModal/CustomizeTripModal';
import OtpConfirmationModal from '../modal/OtpModal/OptModal';
import PlacesWantToExploreModal from '../modal/PlacesWantToExplore/PlacesWantToExplore';
import UserDetailsModal from '../modal/UserDetailsModal/UserDetailsModal';
import { capitalizeFirstLetter } from '../../../Helper/global';
import { MonthsWether } from '../../../constants/SelectedVacationSpotConstant';
import CustomizeTripComponent from '../CustomizeTripComponent/CustomizeTripComponent';
import UserDetailsComponent from '../UserDetailsComponent/UserDetailsComponent';
import MonthTravelling from './MonthTravelling';
import TravellingMonthCalender from './TravellingMonthCalender';
import HolidayDuration from './HolidayDuration';
import HolidayGroup from './HolidayGroup';
import PlacesToExplore from './PlacesToExplore';
import { useUTMSource } from '../../../context/UTMSourceProvider';

const SelectedVacationSpot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const updatedPathName = location?.pathname.replace('/vacation-spot', '');
  const utmSource = useUTMSource();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedCountry =
    location?.state?.selectedCountry ||
    JSON.parse(sessionStorage.getItem('tripDetails'))?.countryName;
  const isTravclan = location?.state?.isTravclan;
  const original_url = location?.pathname;
  const [tabIndex, setTabIndex] = useState(0);
  const tabArray = [0, 1, 2, 3, 4];
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  // const [checked, setChecked] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState('');
  const [selectedPlace, setSelectedPlace] = useState('');
  const [isOpenCutomizeTrip, setIsOpenCustomizeTrip] = useState(false);
  const [whoTravelling, setWhoTravelling] = useState('');
  const [totalPax, setTotalPax] = useState(0);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isOpenPlaceExplore, setIsOpenPlaceExplore] = useState(false);
  const user = 1; //TODO: Set the logged in user details

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const openCustomizeModal = () => setIsOpenCustomizeTrip(true);
  const closeCustomizeModal = () => setIsOpenCustomizeTrip(false);
  const openUserDetails = () => setIsUserDetailsOpen(true);
  const closeUserDetails = () => setIsUserDetailsOpen(false);
  const openOtpModal = () => setIsOtpModalOpen(true);
  const closeOtpModal = () => setIsOtpModalOpen(false);

  const switchToUserDetailsModal = () => {
    closeCustomizeModal();
    openUserDetails();
  };

  const switchToOtpModal = () => {
    closeUserDetails();
    openOtpModal();
  };

  const handleBack = () => {
    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  const handleMonthClick = (month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthIndex = currentDate.getMonth();
    const monthIndex = MonthsWether.findIndex((m) => m.name === month.name);
    const activeStartDate = new Date(currentYear, monthIndex, 1);
    const isPastMonth =
      currentYear > activeStartDate.getFullYear() ||
      (currentYear === activeStartDate.getFullYear() && currentMonthIndex > monthIndex);
    const displayYear = isPastMonth ? currentYear + 1 : currentYear;
    const shouldOpenCurrentYear = monthIndex === currentMonthIndex && displayYear === currentYear;
    const startDate = shouldOpenCurrentYear
      ? activeStartDate
      : new Date(displayYear, monthIndex, 1);

    // Retrieve existing tripDetails from sessionStorage
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    const updatedTripDetails = {
      ...existingTripDetails,
      tripMonth: month.name,
      tripStartDate: startDate.toISOString()
    };
    sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));
    setTabIndex(1);
    setSelectedMonth(isTravclan ? new Date() : startDate);
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setSelectedMonth(activeStartDate);
  };

  const handleCloseVacationSpot = () => {
    navigate('/explore');
  };

  const dayHeaderFormat = (date) =>
    date.toLocaleDateString('en-US', {
      weekday: 'narrow'
    });

  const dayHeaderClassName = (date) => {
    if (date.getDay() === 0) {
      return 'sunday';
    } else if (date.getDay() === 6) {
      return 'saturday';
    } else {
      return 'weekday';
    }
  };

  const renderTileContent = ({ date, view }) => {
    if (view === 'month') {
      if (!Array.isArray(selectedDates)) {
        return null;
      }
      const isSelected = selectedDates.some(
        (selectedDate) =>
          selectedDate.getFullYear() === date.getFullYear() &&
          selectedDate.getMonth() === date.getMonth() &&
          selectedDate.getDate() === date.getDate()
      );
      return isSelected ? <div className="highlight" /> : null;
    }
  };

  const handleTileClick = (date) => {
    if (!Array.isArray(selectedDates)) {
      return;
    }
    const isSelected = selectedDates.some(
      (selectedDate) =>
        selectedDate.getFullYear() === date.getFullYear() &&
        selectedDate.getMonth() === date.getMonth() &&
        selectedDate.getDate() === date.getDate()
    );
    if (isSelected) {
      setSelectedDates((prevSelectedDates) =>
        prevSelectedDates.filter(
          (selectedDate) =>
            selectedDate.getFullYear() !== date.getFullYear() ||
            selectedDate.getMonth() !== date.getMonth() ||
            selectedDate.getDate() !== date.getDate()
        )
      );
    } else {
      setSelectedDates((prevSelectedDates) => [...prevSelectedDates, date]);
    }
  };

  const handleSelectDate = (value) => {
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    const updatedTripDetails = {
      ...existingTripDetails,
      tripDate: value
    };
    setSelectedDates(value);
    setTabIndex(isTravclan ? 1 : 2);
    sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));
  };

  // Function to disable past dates
  // const tileDisabled = ({ date }) => {
  //   const today = new Date();
  //   return date < today;
  // };

  const tileDisabled = ({ date, view }) => {
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(currentDate.getDate() + 90);
    if (view === 'month') {
      if (date < currentDate.setHours(0, 0, 0, 0)) {
        return true;
      }
      if (isTravclan && date > maxDate) {
        return true;
      }
    }
    return false;
  };

  // const tileDisabled = ({ date, view }) => {
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();

  //   const maxDate = new Date();
  //   maxDate.setDate(currentDate.getDate() + 90);

  //   if (view === 'month') {
  //     if (date < currentDate.setHours(0, 0, 0, 0)) {
  //       return true;
  //     }

  //     if (date > maxDate) {
  //       return true;
  //     }

  //     if (isTravclan && date.getFullYear() > currentYear) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  // const handleCheckBox = (event) => {
  //   setChecked(event.target.checked);
  // };

  const handleSelectVacationDays = (days) => {
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    const updatedTripDetails = {
      ...existingTripDetails,
      vacationDays: days
    };
    sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));
    setTabIndex(isTravclan ? 2 : 3);
    setSelectedDuration(days);
  };

  const handleSelectVacationGroup = (group) => {
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    const updatedTripDetails = {
      ...existingTripDetails,
      vacationGroup: group
    };
    sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));
    setWhoTravelling(group);
    setTabIndex(isTravclan ? 3 : 4);
    // setIsOpenCustomizeTrip(true);
  };

  const handleCustomTripTab = () => {
    setTabIndex(isTravclan ? 3 : 5);
    handleTabChange(isTravclan ? 3 : 5);
  };

  const handleCalendarSkip = () => {
    setSelectedDates(null);
    setTabIndex(2);
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    existingTripDetails.tripDate = 'N/A';
    sessionStorage.setItem('tripDetails', JSON.stringify(existingTripDetails));
  };

  const handleSelectPlace = (name) => {
    setSelectedPlace(name);
    //TODO: Need to check whether user logged in or not
    if (user && user > 0) {
      setIsUserDetailsOpen(true);
    }
  };

  const handleEditTrip = () => {
    setIsOpenPlaceExplore(true);
  };

  const setTotalPaxValue = (newTotalPax, callback) => {
    setTotalPax(() => {
      const updatedTotalPax = newTotalPax;
      if (callback) {
        callback(updatedTotalPax);
      }
      return updatedTotalPax;
    });
  };

  const handleCloseModal = (updatedTotalPax) => {
    const pax = updatedTotalPax !== undefined ? updatedTotalPax : totalPax;
    if (pax > 0) {
      setTabIndex(4);
      setIsOpenCustomizeTrip(false);
    } else {
      setIsOpenCustomizeTrip(false);
    }
  };

  const handleClosePlaceExplore = () => {
    setIsOpenPlaceExplore(false);
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
    window.scrollTo(0, 0);
  };

  const getHashTag = (index) => {
    switch (index) {
      case 0:
        return '#month-travelling';
      case 1:
        return '#travelling-month-calendar';
      case 2:
        return '#holiday-duration';
      case 3:
        return '#holiday-group';
      case 4:
        return '#customize-trip';
      case 5:
        return '#user-details';
      case 6:
        return '#places-explore';
      default:
        return '';
    }
  };

  // useEffect(() => {
  //   window.history.replaceState(null, '', `${original_url}${getHashTag(tabIndex)}`);
  // }, [tabIndex]);

  useEffect(() => {
    if (utmSource) {
      const newUrl = `${original_url}${getHashTag(tabIndex)}?utm_source=${utmSource}`;
      window.history.replaceState(null, '', newUrl);
    } else {
      const newUrl = `${original_url}${getHashTag(tabIndex)}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [tabIndex, utmSource]);

  return (
    <Box
      sx={{ px: { xs: 2, sm: 5, md: 10, lg: 18 }, py: 0, borderRadius: 2, background: '#F5F5F5' }}>
      <Box
        className="vacation-centeredStyles"
        value={tabIndex}
        pt={2}
        display="flex"
        alignItems="center">
        {tabIndex !== 0 && (
          <ChevronLeftRounded
            onClick={handleBack}
            sx={{
              cursor: tabIndex >= 1 && tabIndex <= 5 ? 'pointer' : 'default',
              color: tabIndex >= 1 && tabIndex <= 5 ? 'inherit' : 'grey'
            }}
          />
        )}
        <Box mx="auto" display="flex" visibility="hidden" />
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleCloseVacationSpot}
            sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseRounded />
          </IconButton>
        </Box>
      </Box>
      <Box className="vacation-centeredStyles" value={tabIndex} pt={2} pb={2}>
        <Box
          mx="auto"
          display="flex"
          gap={1}
          flexDirection="column"
          sx={{ width: { xs: '100%', sm: '100%', md: 'auto', lg: 'auto', xl: 'auto' } }}>
          <Box
            display="flex"
            sx={{ maxWidth: '100%', whiteSpace: 'nowrap', overflowX: { xs: 'auto', sm: 'auto' } }}>
            {!isTravclan ? (
              <Box display="flex">
                {tabArray.map((index) => (
                  <Box key={index}>
                    {index === 0 && tabIndex >= 0 && (
                      <Typography className="typography-label-style">
                        {capitalizeFirstLetter(selectedCountry)}
                      </Typography>
                    )}
                    {selectedDates && index === 2 && tabIndex >= 2 && (
                      <Typography className="typography-label-style" ml={2}>
                        {moment(selectedDates).format('DD MMM YYYY')}
                      </Typography>
                    )}
                    {index === 3 && tabIndex >= 3 && (
                      <Typography className="typography-label-style" ml={2}>
                        {selectedDuration}
                      </Typography>
                    )}
                    {index === 4 && tabIndex >= 4 && (
                      <Box display="flex" alignItems="center">
                        <Typography className="typography-label-style" ml={2}>
                          {whoTravelling}
                        </Typography>
                        {totalPax != 0 && (
                          <Typography className="typography-label-style" ml={1}>
                            {totalPax} pax
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <Box display="flex">
                {tabArray.map((index) => (
                  <Box key={index}>
                    {index === 0 && tabIndex >= 0 && (
                      <Typography className="typography-label-style">
                        {capitalizeFirstLetter(selectedCountry)}
                      </Typography>
                    )}
                    {selectedDates && index === 2 && tabIndex >= 2 && (
                      <Typography className="typography-label-style" ml={2}>
                        {moment(selectedDates).format('DD MMM YYYY')}
                      </Typography>
                    )}
                    {index === 3 && tabIndex >= 3 && (
                      <Box display="flex" alignItems="center">
                        <Typography className="typography-label-style" ml={2}>
                          {whoTravelling}
                        </Typography>
                        {totalPax != 0 && (
                          <Typography className="typography-label-style" ml={1}>
                            {totalPax} pax
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box mx="auto" display="flex">
            {tabArray.map((index) => (
              <Box
                key={index}
                sx={{
                  mr: 1,
                  height: { xs: 4, sm: 8 },
                  width: { xs: 56.8, sm: 142 },
                  borderRadius: 2,
                  backgroundColor: tabIndex >= index ? '#27306B' : '#27306B1A',
                  position: 'relative'
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {!isTravclan ? (
        <Box sx={{ px: { xs: 2, sm: 5, md: 4, lg: 20 } }} mb={2}>
          {tabIndex === 0 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Which month are you travelling?
              </Typography>
              <MonthTravelling
                handleMonthClick={handleMonthClick}
                handleTabChange={handleTabChange}
              />
            </>
          )}
          {tabIndex === 1 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Which month are you travelling?
                <span
                  onClick={() => {
                    handleCalendarSkip();
                    handleTabChange(2);
                  }}
                  style={{
                    float: 'right',
                    color: '#27306B',
                    cursor: 'pointer',
                    backgroundColor: '#f0f0f0',
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    transition: 'background-color 0.3s'
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#e0e0e0';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '#f0f0f0';
                  }}>
                  Skip &gt;&gt;
                </span>
              </Typography>
              <TravellingMonthCalender
                selectedMonth={selectedMonth}
                dayHeaderFormat={dayHeaderFormat}
                dayHeaderClassName={dayHeaderClassName}
                selectedDates={selectedDates}
                renderTileContent={renderTileContent}
                handleTileClick={handleTileClick}
                handleTabChange={handleTabChange}
                handleSelectDate={handleSelectDate}
                handleActiveStartDateChange={handleActiveStartDateChange}
                tileDisabled={tileDisabled}
              />
            </>
          )}
          {tabIndex === 2 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                What's your Holiday Duration?
              </Typography>
              <HolidayDuration
                handleSelectVacationDays={handleSelectVacationDays}
                handleTabChange={handleTabChange}
                isSmallScreen={isSmallScreen}
                isTravclan={isTravclan}
              />
            </>
          )}
          {tabIndex === 3 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Who is travelling with you?
              </Typography>
              <HolidayGroup
                handleSelectVacationGroup={handleSelectVacationGroup}
                handleTabChange={handleTabChange}
                isSmallScreen={isSmallScreen}
                isTravclan={isTravclan}
              />
            </>
          )}
          {tabIndex === 4 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Customize Your Trip!
              </Typography>
              <CustomizeTripComponent
                handleCustomTripTab={handleCustomTripTab}
                setTotalPax={setTotalPaxValue}
                whoTravelling={whoTravelling}
              />
            </>
          )}
          {tabIndex === 5 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                User Details
              </Typography>
              <UserDetailsComponent
                openCustomizeModal={openCustomizeModal}
                handleOtpModal={switchToOtpModal}
                updatedPathName={updatedPathName}
              />
            </>
          )}
          {tabIndex === 6 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Places you want to Explore.{' '}
                <span
                  onClick={() => {
                    handleCalendarSkip();
                    handleTabChange(2);
                  }}
                  style={{
                    float: 'right',
                    color: '#27306B',
                    cursor: 'pointer',
                    backgroundColor: '#f0f0f0',
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    transition: 'background-color 0.3s'
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#e0e0e0';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '#f0f0f0';
                  }}>
                  Skip &gt;&gt;
                </span>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Find a City"
                style={{ marginBottom: 20 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRounded className="search-icon" />
                    </InputAdornment>
                  )
                }}
              />
              <PlacesToExplore
                handleSelectPlace={handleSelectPlace}
                isSmallScreen={isSmallScreen}
                selectedPlace={selectedPlace}
                handleEditTrip={handleEditTrip}
              />
            </>
          )}
        </Box>
      ) : (
        <Box sx={{ px: { xs: 2, sm: 5, md: 4, lg: 20 } }} mb={2}>
          {tabIndex === 0 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Which month are you travelling?
              </Typography>
              <TravellingMonthCalender
                selectedMonth={selectedMonth}
                dayHeaderFormat={dayHeaderFormat}
                dayHeaderClassName={dayHeaderClassName}
                selectedDates={selectedDates}
                renderTileContent={renderTileContent}
                handleTileClick={handleTileClick}
                handleTabChange={handleTabChange}
                handleSelectDate={handleSelectDate}
                handleActiveStartDateChange={handleActiveStartDateChange}
                tileDisabled={tileDisabled}
              />
            </>
          )}
          {/* {tabIndex === 1 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                What's your Holiday Duration?
              </Typography>
              <HolidayDuration
                handleSelectVacationDays={handleSelectVacationDays}
                handleTabChange={handleTabChange}
                isSmallScreen={isSmallScreen}
                isTravclan={isTravclan}
              />
            </>
          )} */}
          {tabIndex === 1 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Who is travelling with you?
              </Typography>
              <HolidayGroup
                handleSelectVacationGroup={handleSelectVacationGroup}
                handleTabChange={handleTabChange}
                isSmallScreen={isSmallScreen}
                isTravclan={isTravclan}
              />
            </>
          )}
          {tabIndex === 2 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Customize Your Trip!
              </Typography>
              <CustomizeTripComponent
                handleCustomTripTab={handleCustomTripTab}
                setTotalPax={setTotalPaxValue}
                whoTravelling={whoTravelling}
              />
            </>
          )}
          {tabIndex === 3 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                User Details
              </Typography>
              <UserDetailsComponent
                openCustomizeModal={openCustomizeModal}
                handleOtpModal={switchToOtpModal}
                updatedPathName={updatedPathName}
              />
            </>
          )}
          {tabIndex === 4 && (
            <>
              <Typography
                className={`${
                  isSmallScreen ? 'small-device-typography' : 'regular-device-typography'
                }`}
                sx={{ mb: 4 }}>
                Places you want to Explore.{' '}
                <span
                  onClick={() => {
                    handleCalendarSkip();
                    handleTabChange(2);
                  }}
                  style={{
                    float: 'right',
                    color: '#27306B',
                    cursor: 'pointer',
                    backgroundColor: '#f0f0f0',
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    transition: 'background-color 0.3s'
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#e0e0e0';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '#f0f0f0';
                  }}>
                  Skip &gt;&gt;
                </span>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Find a City"
                style={{ marginBottom: 20 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRounded className="search-icon" />
                    </InputAdornment>
                  )
                }}
              />
              <PlacesToExplore
                handleSelectPlace={handleSelectPlace}
                isSmallScreen={isSmallScreen}
                selectedPlace={selectedPlace}
                handleEditTrip={handleEditTrip}
              />
            </>
          )}
        </Box>
      )}

      {isOpenCutomizeTrip && (
        <CustomizeTripModal
          open={openCustomizeModal}
          handleClose={handleCloseModal}
          setTotalPax={setTotalPaxValue}
          handleUserDetailsModal={switchToUserDetailsModal}
        />
      )}
      <UserDetailsModal
        open={isUserDetailsOpen}
        openCustomizeModal={openCustomizeModal}
        handleClose={closeUserDetails}
        handleOtpModal={switchToOtpModal}
      />
      <OtpConfirmationModal open={isOtpModalOpen} handleClose={closeOtpModal} />
      <PlacesWantToExploreModal open={isOpenPlaceExplore} handleClose={handleClosePlaceExplore} />
    </Box>
  );
};

export default SelectedVacationSpot;
