export const BranchList = [
  {
    city: 'MUMBAI-HO',
    manager: 'Rushi / Ajinkya',
    address: '1-4,4A,KINGS CORNER CHS, L.J. ROAD, MAHIM, MUMBAI 400016',
    phone: '+919167774978',
    landline: '02261744128',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'BORIVALI',
    manager: 'Omkar',
    address: 'Shop no 6 & 7 Dream House CHS, CHANDAVARKAR LANE, Borivali-W,Mumbai 400092',
    phone: '+919167774972',
    landline: '022 69582200',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'VASHI',
    manager: 'Namrata',
    address: 'Shop No. 22/23, Ground Floor Real Tech Park Sector 30, Vashi, Navi Mumbai 400705',
    phone: '+91-8657924037',
    landline: '022-69462222',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'CHEMBUR',
    manager: 'Deepa V',
    address:
      'SHOP NO 09 , CS LTD KRUSHAL SHOPPING COMPLEX Near Westside, Tilak Nagar CHEMBUR(W) MUMBAI 400089',
    phone: '7045000091',
    landline: '7045000091',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'THANE',
    manager: 'Siddesh / Ajinkya',
    address:
      'Malti Mohan Building, 1st floor Above horizon hospital Opposite Saraswati Secondary Marathi Medium School ,Naupada THANE(W) 400602',
    phone: '9167835083',
    landline: '022 69721100',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'NAGPUR',
    manager: 'Radhika',
    address: 'MILESTONE",2 FLOOR, 12 RAMDASPETH,WARDHA ROAD, NAGPUR 440010',
    phone: '8657924776',
    landline: '07126921717',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'NASHIK',
    manager: 'Ajinkya',
    address: 'Shri Shankaracharya Sankul, Near Old Gangapur Naka, Gangapur Road NASHIK 422013',
    phone: '8657924773',
    landline: '0253-6914444',
    email: 'holiday@.in'
  },
  {
    city: 'PUNE, F.C ROAD',
    manager: 'Harshala',
    address: 'LAND SQUARE BLDG,F.C ROAD NEXT TO HOTEL RUPALI, SHIVAJI NAGAR,PUNE 411004',
    phone: '9167835071',
    landline: '020 66465555',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'PUNE, BANER',
    manager: 'Harshala',
    address: '265/2, OPP BPCL PETROLEUM BANER PUNE 411045',
    phone: '09167835155',
    landline: '02069101212',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'KOLHAPUR',
    manager: 'Komal',
    address: 'ANANT TOWER,1ST FLR,SYKES EXNT NR.SHAHAJI LAW COLLEGE, KOLHAPUR 416001',
    phone: '+919167835171',
    landline: '0231 2533222',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'BANGALORE',
    manager: 'Dileepkumar',
    address: '#806,1ST FLOOR,27TH MAIN ROAD 1ST SECTOR HSR LAYOUT BANGALORE 560102',
    phone: '95139 99204',
    landline: '080 4091 1845',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'BANGALORE',
    manager: 'Dileepkumar',
    address: '#223,MANANDI TOWERS 9TH MAIN,3RD BLOCK JAYANAGAR BANGALORE 560011',
    phone: '+91 95139 99028',
    landline: '080 40911639/ 1721',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'BANGALORE',
    manager: 'Dileepkumar',
    address: 'F520, SAHAKARA NAGAR MAIN ROAD F BLOCK,SAHAKAR NAGAR, BANGALORE 560092',
    phone: '95139 57736',
    landline: '08065113100',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'BANGALORE',
    manager: 'Dileepkumar',
    address: 'No. 17 1st Flr Shah Sultan, Complext Ali Asker Road, Cunningham Rd, Bangalore 560052',
    phone: '7829919386',
    landline: '080 69071000',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'DELHI',
    manager: 'Ankit / Ajinkya',
    address: 'A-95, Municipal Market, Opp. Haldiram, Connaught Place,New Delhi 110001',
    phone: '7045000081/8291569018',
    landline: '1145830070',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'AHMEDABAD',
    manager: 'Krishna',
    address:
      '414, Crystal Arcade, Near Navrangpura Telephone Exchange, C.G. Road, Ahmedabad 380009',
    phone: '+919898099073',
    landline: '079 26561515',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'CHANDIGARH',
    manager: 'Gautam',
    address: 'SCO 28,29,30 First Floor Sector 9D CHANDIGARH 160009',
    phone: '9769112280',
    landline: '0172 410 9600',
    email: 'holiday@kesariselect.in'
  },
  {
    city: 'Kerala',
    manager: '',
    address: '1st Flr Mangat Acrade, Kallor-Kadavantra Road Kadavanthra, Kerala 682020',
    phone: '',
    landline: '1800 22 1100 (Toll-Free)',
    email: 'holiday@kesariselect.in'
  }

  // {
  //   city: 'DOMBIVLI',
  //   manager: 'Priya',
  //   address: 'SHOP NO 03 SAI ARCADE Near Ganpati Mandir, Phadke Road DOMBIVLI EAST 421201',
  //   phone: '7045000041',
  //   landline: '0251244 0041',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'HYDERABAD',
  //   manager: 'Hepsiba',
  //   address:
  //     'Ground Floor, Shop No.1, Vista Grand Towers Raj Bhawan Road, Somajiguda, HYDERABAD 500082',
  //   phone: '6301877097',
  //   landline: '914065144000',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'JAIPUR',
  //   manager: 'Rajkamal',
  //   address: '16-KA-4, Jyoti Nagar, Sahakar Marg JAIPUR 302005',
  //   phone: '9152061161',
  //   landline: '0141-4817309',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'KOLKATA',
  //   manager: 'Ranjana',
  //   address:
  //     '1st Floor, 8/1A, Larsen and Toubro Building, 3B Shakespeare Sarani, Little Russel Street, West Bengal, India KOLKATA 700071',
  //   phone: '+91 9167775517',
  //   landline: '(033) 6520 3000',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'LUCKNOW',
  //   manager: 'Rajiv',
  //   address: 'KANPUR 208001',
  //   phone: '9839707052',
  //   landline: '',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'VASAI',
  //   manager: 'Nutan',
  //   address:
  //     'SHOP NO.1,GROUND FLOOR,HIRA NIKETAN, AMBADI ROAD CORNER,NEAR WELLNESS FOREVER MEDICAL STATION ROAD VASAI (WEST) 401202',
  //   phone: '70450 00031',
  //   landline: '02506922000',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'VILE PARLE',
  //   manager: 'Mrunal',
  //   address:
  //     'Casablanca, Shop no. 2, Behind Suncity Theatre, M.G. Road, Vile Parle (E) MUMBAI 400057',
  //   phone: '022 6960 2000',
  //   landline: '+91 7045234545',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'Panvel',
  //   manager: '',
  //   address: 'Online',
  //   phone: '',
  //   landline: '1800 22 1100 (Toll-Free)',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'Girgaon',
  //   manager: '',
  //   address: 'Online',
  //   phone: '',
  //   landline: '1800 22 1100 (Toll-Free)',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'Goregaon',
  //   manager: '',
  //   address: 'Online',
  //   phone: '',
  //   landline: '1800 22 1100 (Toll-Free)',
  //   email: 'holiday@kesariselect.in'
  // },
  // {
  //   city: 'Indore',
  //   manager: '',
  //   address: 'Online',
  //   phone: '',
  //   landline: '1800 22 1100 (Toll-Free)',
  //   email: 'holiday@kesariselect.in'
  // }
];
