/* eslint-disable no-unused-vars */
import React from 'react';
import './CareersWelCome.css';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';
import { IMAGE_DIR_PATH, PUBLIC_CMS_URL } from '../../../APIs/endpoint';
import CustomButton from '../../common/Button';
import KsJoinUsImg from '../../../assets/Images/KS-JoinUs.jpg';

const CareersWelCome = ({ timeLineSliderData, isLoader, onScrollToOpenings }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: true,
    fade: false,
    infinite: true,
    arrows: true,
    autoplay: !isLoader,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#27306B', color: '#FFFFFF' }}>
      <Grid container spacing={4} sx={{ px: { xs: 2, sm: 4, md: 7 } }}>
        {/* Left Side */}
        <Grid item xs={12} md={6} lg={6} alignContent="center">
          <Typography variant="h4" sx={{ fontFamily: 'gothamBold', mb: 2 }}>
            Welcome to Kesari Select!
          </Typography>
          <Typography sx={{ fontFamily: 'gothamBook', mb: 4 }}>
            Work. Learn. Succeed. At Kesari Select, we offer an exciting work culture, amazing
            colleagues, and a supportive environment where you can flourish.
          </Typography>
          {/* <CustomButton
            className="everyStep_typo_btn"
            onClick={onScrollToOpenings}
            showArrow={true}>
            <Typography fontFamily="gothamBold">View current openings</Typography>
          </CustomButton> */}
        </Grid>

        {/* Right Side */}
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            height: { sm: '220px', md: '250px', lg: '280px' },
            alignContent: 'center',
            paddingLeft: { lg: '100px' },
            paddingTop: {
              xs: '0px !important',
              sm: '32px !important',
              md: '32px !important',
              lg: '32px'
            }
          }}>
          <Box
            sx={{
              height: '100%',
              width: '100%'
            }}>
            <img
              src={KsJoinUsImg}
              alt="Join Us"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
            {/* <Slider className="career-wl-parent_component" {...settings}>
              {timeLineSliderData
                ?.sort((a, b) => a.timelineslider_sequence_no - b.timelineslider_sequence_no)
                .map((item, i) => {
                  const imageSrc = isMobile
                    ? `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.mobile_timeline_image}` // Mobile image
                    : `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.timeline_image}`; // Desktop image
                  return (
                    <Box
                      key={i}
                      className="img_section"
                      // onClick={() => handleImageClick(item.timeline_url, item.timeline_id)}
                      sx={{ cursor: 'pointer' }}>
                      <img
                        loading={i === 0 ? 'eager' : 'lazy'}
                        width={1500}
                        height={570}
                        srcSet={`
                    ${imageSrc}?w=300 300w,
                    ${imageSrc}?w=768 768w,
                    ${imageSrc}?w=1024 1024w
                `}
                        sizes="(max-width: 600px) 300px, (max-width: 1024px) 768px, 1024px"
                        src={imageSrc}
                        alt={`Timeline slide ${i + 1}`}
                      />
                    </Box>
                  );
                })}
            </Slider> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareersWelCome;
