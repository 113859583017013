import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const Empowering = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        pt: 4,
        px: isSmallScreen ? 2 : isMediumScreen ? 6 : 14
      }}>
      <Typography fontFamily="gothamBold" fontSize="26px" sx={{ mb: 1 }}>
        Our Philosophy
      </Typography>
      <Typography fontFamily="gothamBook" fontSize="16px" mb={0} color="#5F5F5F">
        At Kesari Select, we’re on a mission to make customized travel more accessible for everyone.
      </Typography>
      <Typography fontFamily="gothamBook" fontSize="16px" mb={3} color="#5F5F5F">
        To achieve this, we seek passionate individuals who bring energy, creativity, and a love for
        exploration to everything they do.
      </Typography>
    </Box>
  );
};

export default Empowering;
