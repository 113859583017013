import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TravclanPackageCard from './TravclanPackageCard';

const Travclan = ({ travclanPackages, isLoader, selectedCurrency, isListing, isDetails }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [slider, setSlider] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clickDisabled, setClickDisabled] = useState(false);
  const travclanDataLength = travclanPackages && travclanPackages.length;

  const getSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 600) return 1.2;
    if (width < 960) return 2.3;
    return 3.6;
  };

  const slidesToShow = getSlidesToShow();

  const goToPrev = () => {
    if (!clickDisabled && slider && currentIndex > 0) {
      setClickDisabled(true);
      slider.slickPrev();
      setTimeout(() => setClickDisabled(false), 500);
    }
  };

  const goToNext = () => {
    if (!clickDisabled && slider && currentIndex < travclanPackages.length - 1) {
      setClickDisabled(true);
      slider.slickNext();
      setTimeout(() => setClickDisabled(false), 500);
    }
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: false,
    beforeChange: (_, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.6,
          centerMode: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2
        }
      }
    ]
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '1900px',
          margin: '0px',
          overflow: isListing ? '' : 'hidden',
          backgroundColor: isListing ? 'inherit' : '#ffffff',
          width: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '65px',
            background: 'linear-gradient(90deg, #27306B, #E41E25)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            mb: '20px',
            textAlign: 'center',
            color: '#fff',
            position: 'relative'
          }}>
          <Typography
            sx={{
              fontFamily: 'gothamBold',
              fontSize: { xs: '20px', sm: '24px', md: '30px', lg: '32px' },
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
              zIndex: 1
            }}>
            Get Your Travel Quote in Seconds!
          </Typography>
          {!isSmallScreen && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                right: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                transform: 'translateY(-50%)'
              }}>
              <ChevronLeft
                onClick={goToPrev}
                sx={{
                  padding: '7px',
                  boxSizing: 'content-box',
                  width: '18px',
                  height: '18px',
                  border: '1px solid',
                  borderColor: currentIndex === 0 ? '#0000004D' : '#fff',
                  borderRadius: '100%',
                  color: currentIndex === 0 ? '#000' : '#fff',
                  mr: '14px',
                  cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
                  pointerEvents: currentIndex === 0 ? 'none' : 'auto',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
                  }
                }}
              />
              <ChevronRight
                onClick={goToNext}
                sx={{
                  padding: '7px',
                  boxSizing: 'content-box',
                  width: '18px',
                  height: '18px',
                  border: '1px solid',
                  borderColor:
                    currentIndex >= travclanDataLength - slidesToShow ? '#0000004D' : '#fff',
                  borderRadius: '100%',
                  color: currentIndex >= travclanDataLength - slidesToShow ? '#000' : '#fff',
                  cursor:
                    currentIndex >= travclanDataLength - slidesToShow ? 'not-allowed' : 'pointer',
                  pointerEvents:
                    currentIndex >= travclanDataLength - slidesToShow ? 'none' : 'auto',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
                  }
                }}
              />
            </Box>
          )}
        </Box>

        {travclanDataLength > 0 ? (
          <Box
            sx={{
              pl: {
                xs: '15px',
                sm: '10px',
                md: 'calc(10% - 20px)',
                lg: isListing || isDetails ? 'calc(10% - 50px)' : 'calc(10% - 20px)'
              }
            }}>
            <Slider {...settings} ref={(slider) => setSlider(slider)} className="card-list">
              {travclanPackages.map((item, index) => (
                <Box key={index}>
                  <TravclanPackageCard
                    tourPackage={item}
                    isLoader={isLoader}
                    selectedCurrency={selectedCurrency}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        ) : (
          <Box className="travellife_title">
            <Typography
              sx={{
                fontSize: { xs: '22px', md: '44px' },
                fontFamily: 'gothamBold',
                textAlign: 'center',
                height: travclanDataLength === 0 ? '205px' : 'auto',
                alignContent: 'center'
              }}
              className="travellife_title_head">
              Coming Soon...!
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Travclan;
