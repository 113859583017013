import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../APIs/api';
import { API_URL, BASE_URL } from '../APIs/endpoint';
import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDataList } from '../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../APIs/metaDataSlug';
import { HelmetProvider } from 'react-helmet-async';
import { toast } from 'react-toastify';
import PackageBanner from '../components/package_detail/package_banner/PackageBanner';
import PackageDetailsSection2 from '../components/package_detail/package_detials_section2/PackageDetialsSection2';
import PackageCustomizeTourButton from '../components/package_detail/package_customize_tour_button/PackageCustomizeTourButton';
// import RelatedTour from '../components/common/related_tour/RelatedTour';
import HelmetComponent from '../components/MetaDataComponent/Helmet';
import ReviewCard from '../components/home/ReviewCommonCard/ReviewCommonCard';

const PackageDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const reviewRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedPackageId = location?.state?.packageId;
  const currentPathName = location?.pathname;
  const productId = location?.pathname.split('/').pop();
  const isNewSouthWale = location?.pathname.startsWith('/destination/new-south-wales');
  const isQueensland = location?.pathname.startsWith('/destination/queensland');

  const [packageDetails, setPackageDetails] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [isSticky, setIsSticky] = useState(true);
  const productDetailsMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);

  const getTourPackageDetailsApi = async () => {
    try {
      setIsLoader(true);
      const detailsResponse = await api.get(
        `${BASE_URL}${API_URL.GET_PACKAGE_DETAILS}?package_id=${selectedPackageId || productId}`
      );
      const { status, data, message } = detailsResponse;
      if (status) {
        if (Object.keys(data)?.length > 0) {
          setPackageDetails(data);
        } else {
          navigate(-1);
        }
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getReviewListApi = async () => {
    try {
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      setIsLoader(true);
      const { status, data, message } = reviewRes;
      if (status) {
        setReviewData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.PRODUCT_DETAILS_SLUG })).finally(() =>
      setIsLoader(false)
    );
    getTourPackageDetailsApi();
    getReviewListApi();
    window.scroll(0, 0);

    let lastScrollY = 0;
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const currentScrollY = window.scrollY;
          if (currentScrollY > lastScrollY) {
            if (reviewRef.current && isElementInViewport(reviewRef.current)) {
              setIsSticky(false);
            }
          } else {
            if (reviewRef.current && !isElementInViewport(reviewRef.current)) {
              setIsSticky(true);
            }
          }
          lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
          ticking = false;
        });
        ticking = true;
      }
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.bottom <= window.innerHeight;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={productDetailsMetaData} />
      </HelmetProvider>
      {isLoader ? (
        <Skeleton variant="text" width="100%" height={500} />
      ) : (
        <>
          <PackageBanner packageDetails={packageDetails} isLoader={isLoader} />
          <PackageDetailsSection2
            packageDetails={packageDetails}
            isLoader={isLoader}
            isNewSouthWale={isNewSouthWale}
            isQueensland={isQueensland}
            currentPathName={currentPathName}
          />
          {isMobile && (
            <PackageCustomizeTourButton
              packageDetails={packageDetails}
              isSticky={isSticky}
              isNewSouthWale={isNewSouthWale}
              isQueensland={isQueensland}
              currentPathName={currentPathName}
            />
          )}
          {!isNewSouthWale && !isQueensland && (
            <Box ref={reviewRef}>
              <ReviewCard reviewData={reviewData} isLoader={isLoader} isDetails={true} />
            </Box>
          )}
          {/* <RelatedTour /> */}
        </>
      )}
    </>
  );
};

export default PackageDetails;
