import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
import { useSelector } from 'react-redux';
import { VideoData } from '../../constants/QueenslandConstant';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
// import QueenslandGetInTouch from '../../components/QueenslandComponents/QueenslandGetInTouch';
import QueenslandVideoPlayer from '../../components/QueenslandComponents/QueenslandVideoPlayer';
import QueenslandHomeBanner from '../../components/QueenslandComponents/QueenslandHomeBanner';
import VideoGalleryBanner from '../../assets/Images/queensland/Queensland-video-gallery-banner.jpg';
import VideoGalleryMobileBanner from '../../assets/Images/queensland/Queensland-video-gallery-mobile-banner.jpg';

const QueenslandVideoPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [isLoader, setIsLoader] = useState(false);
  const [videoDataList, setVideoDataList] = useState([]);

  useEffect(() => {
    setIsLoader(true);
    window.scrollTo(0, 0);
    setVideoDataList(VideoData);
    setIsLoader(false);
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      <QueenslandHomeBanner bannerImg={isMobile ? VideoGalleryMobileBanner : VideoGalleryBanner} />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2, px: 4 }}>
        <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '18px', md: '22px' },
            fontFamily: 'gothamBold',
            textAlign: 'center',
            color: '#27306B'
          }}>
          EXPERIENCE QUEENSLAND
        </Typography>
        <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
      </Box>
      <Box sx={{ px: { xs: 2, sm: 3, md: 5 }, py: 4 }}>
        <Grid container spacing={3}>
          {videoDataList.map((item, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
              <QueenslandVideoPlayer
                videoUrl={item.url}
                videoTitle={item.title}
                isLoader={isLoader}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <QueenslandGetInTouch /> */}
    </>
  );
};

export default QueenslandVideoPage;
