import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ApplyImage from '../../assets/Images/apply.png';
import InterviewImage from '../../assets/Images/interview.png';
import AboardImage from '../../assets/Images/aboard.png';

const HowWeHire = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      pt: { xs: 2, sm: 4 },
      px: { xs: 2, sm: 4, md: 10, lg: 21 },
      backgroundColor: '#f5f5f5'
    }}>
    <Typography
      sx={{ mb: 2, fontFamily: 'gothamBold', fontSize: { xs: '24px', sm: '30px', md: '36px' } }}>
      Our Process
    </Typography>
    <Box>
      <Typography sx={{ mb: 1, fontFamily: 'gothamBook', fontSize: { xs: '16px', sm: '18px' } }}>
        We believe that passion fuels excellence—it's what transforms ordinary individuals into
        extraordinary trailblazers.
      </Typography>
      <Typography sx={{ fontFamily: 'gothamBook', fontSize: { xs: '16px', sm: '18px' } }}>
        If you have the drive, determination, and a spirit of adventure, you might be just the
        person we’re looking for!
      </Typography>
    </Box>

    <Grid container mt={2} pb={4} spacing={3} justifyContent="center">
      {[
        {
          image: ApplyImage,
          title: 'Apply',
          text: 'Discover the perfect role for you and submit your application. Be authentic—this helps us see the real you and how you can make an impact.'
        },
        {
          image: InterviewImage,
          title: 'Interview',
          text: 'Come prepared, ask questions, and be yourself! Interviews are a two-way conversation to ensure the right fit for both you and us.'
        },
        {
          image: AboardImage,
          title: 'Join the Journey',
          text: 'If we’re a great match, we’ll welcome you aboard! We appreciate your patience during this process and can’t wait to have you on our adventure.'
        }
      ].map((step, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          display="flex"
          flexDirection="column"
          alignItems="center">
          <img
            src={step.image}
            alt={step.title}
            style={{ width: '100%', maxWidth: '220px', height: '220px', borderRadius: '8px' }}
          />
          <Typography variant="h6" sx={{ mt: 2, fontFamily: 'gothamBold' }}>
            {step.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
              fontFamily: 'gothamBook',
              fontSize: { xs: '14px', sm: '16px' },
              maxWidth: '90%'
            }}>
            {step.text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default HowWeHire;
