export const menuList = [
  {
    menu: 'India',
    drop: true
  },
  {
    menu: 'World',
    drop: true
  },
  // {
  //   menu: 'Customize Holidays'
  // },
  // {
  //   menu: 'Hidden Gems'
  // },
  // {
  //   menu: 'Hidden Getaways'
  // },
  {
    menu: 'Themed Experience'
  },
  {
    menu: 'Ready To Book'
  }
];

export const mostRecentlyVisitedIndianPlace = [
  {
    title: 'Jammu & Kashmir',
    url: '/explore/india/north-india/jammu-and-kashmir/jammu'
  },
  {
    title: 'Rajasthan',
    url: '/explore/india/west-india/rajasthan'
  },
  {
    title: 'Leh Ladakh',
    url: '/explore/india/north-india/leh-and-ladakh'
  },
  {
    title: 'Himachal Pradesh',
    url: '/explore/india/north-india/himachal-pradesh/manali'
  },
  {
    title: 'Kerala',
    url: '/explore/india/south-india/kerala'
  },
  {
    title: 'Andaman & Nicobar',
    url: '/explore/india/south-india/andaman-and-nicobar-islands'
  }
];

export const mostRecentlyVisitedWorldPlace = [
  {
    title: 'Dubai',
    url: '/explore/world/asia/united-arab-emirates/dubai'
  },
  {
    title: 'Indonesia',
    url: '/explore/world/asia/indonesia'
  },
  {
    title: 'Mauritius',
    url: '/explore/world/africa/mauritius'
  },
  {
    title: 'Australia',
    url: '/explore/world/australia/'
  },
  {
    title: 'France',
    url: '/explore/world/europe/france'
  },
  {
    title: 'United States',
    url: '/explore/world/north-america/united-states/new-york'
  }
];

export const newSouthWaleMenuList = [
  {
    menu: 'Customized Holidays'
  },
  {
    menu: 'Video Gallery'
  },
  {
    menu: 'Photo Gallery'
  }
];

export const queenslandMenuList = [
  {
    menu: 'Customized Holidays'
  },
  {
    menu: 'Group Tours'
  },
  {
    menu: 'Videos'
  },
  {
    menu: 'Photo Gallery'
  },
  {
    menu: 'Blogs'
  }
];
