/* eslint-disable no-unused-vars */
import React, { useRef, useState, forwardRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { LocationOn, AccessTime, EastRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const filters = {
  departments: [
    'Sales',
    'InfoSale',
    'MICE',
    'Sales - GIT/FIT',
    'Sales - Channel Sales',
    'Visa',
    'Operation - MICE',
    'Product - GIT',
    'Product - FIT',
    'Contracting - GIT/MICE',
    'Air Reservations - GIT/FIT',
    'Assistant Manager',
    'Manager',
    'Incharge',
    'Sr. Executive',
    'Business Development'
  ],
  locations: [
    'Mumbai',
    'Hyderabad',
    'Pune',
    'Bangalore',
    'Mumbai - Vidhyavihar',
    'Indore',
    'Ahmedabad',
    'Kolkata'
  ]
};

const CareersOpenings = forwardRef(({ vacanciesData }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const clearFlag = useRef(false);

  const navigate = useNavigate();

  const formatForURL = (text) =>
    text
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]+/g, '-')
      .replace(/-+/g, '-');

  const formatPositionForURL = (position) =>
    position
      .toLowerCase()
      .replace(/sr\./g, 'sr')
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]+/g, '-')
      .replace(/-+/g, '-');

  const handleFilterChange = (event, setFilter) => {
    if (clearFlag.current) {
      clearFlag.current = false;
      return;
    }

    const {
      target: { value }
    } = event;
    const validValues = Array.isArray(value) ? value.filter((v) => v !== undefined) : [];
    setFilter(validValues);
  };

  const renderSelectedValues = (selected) => {
    if (selected.length === 0) return '';
    if (selected.length === 1) {
      return selected[0];
    } else {
      return `${selected[0]} (${selected.length - 1}+ other${selected.length > 2 ? 's' : ''})`;
    }
  };

  const handleClearAll = (setFilter) => {
    clearFlag.current = true;
    setFilter([]);
  };

  const filteredJobs = vacanciesData.filter((job) => {
    const departmentMatch =
      selectedDepartments.length === 0 || selectedDepartments.includes(job.vacancy_department);
    const locationMatch =
      selectedLocations.length === 0 ||
      selectedLocations.some((location) => (job.vacancy_location || '').includes(location));
    return departmentMatch && locationMatch;
  });

  return (
    <Box ref={ref} px={isMobile ? 2 : 8} py={4}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        mb={4}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobile ? 'center' : 'start'}
          textAlign={isMobile ? 'center' : 'left'}>
          <Typography variant="h4" fontFamily="gothamBold" gutterBottom>
            Current Openings
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            fontFamily="gothamBook"
            mb={isMobile ? 2 : 4}>
            We're currently looking to fill the following roles on our team.
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 1 : 2}
          width={isMobile ? '100%' : 'auto'}>
          <Typography variant="h6" minWidth="80px" sx={{ display: { xs: 'none' } }}>
            Filter by
          </Typography>
          <TextField
            fullWidth
            select
            label="All Departments"
            size="small"
            value={selectedDepartments}
            onChange={(event) => handleFilterChange(event, setSelectedDepartments)}
            SelectProps={{
              multiple: true,
              renderValue: (selected) => renderSelectedValues(selected),
              MenuProps: {
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                PaperProps: {
                  sx: { maxWidth: { xs: '100%', md: 200 }, maxHeight: 300, overflowY: 'auto' }
                }
              }
            }}
            sx={{ width: { xs: '100%', md: 240 } }}>
            {filters.departments.map((department, index) => (
              <MenuItem key={index} value={department}>
                <Checkbox checked={selectedDepartments.includes(department)} />
                <ListItemText primary={department} />
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={() => handleClearAll(setSelectedDepartments)}
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#FFF',
                zIndex: 1,
                '&:hover': { backgroundColor: '#FFF' }
              }}>
              <Typography color="primary" variant="body2">
                Clear All
              </Typography>
            </MenuItem>
          </TextField>

          <TextField
            fullWidth
            select
            label="All Locations"
            size="small"
            value={selectedLocations}
            onChange={(event) => handleFilterChange(event, setSelectedLocations)}
            SelectProps={{
              multiple: true,
              renderValue: (selected) => renderSelectedValues(selected),
              MenuProps: {
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                PaperProps: {
                  sx: { maxWidth: isMobile ? '100%' : 200, maxHeight: 250, overflowY: 'auto' }
                }
              }
            }}
            sx={{ width: { xs: '100%', md: 240 } }}>
            {filters.locations.map((location, index) => (
              <MenuItem key={index} value={location}>
                <Checkbox checked={selectedLocations.includes(location)} />
                <ListItemText primary={location} />
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={() => handleClearAll(setSelectedLocations)}
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#FFF',
                zIndex: 1,
                '&:hover': { backgroundColor: '#FFF' }
              }}>
              <Typography color="primary" variant="body2">
                Clear All
              </Typography>
            </MenuItem>
          </TextField>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {filteredJobs.length > 0 ? (
          filteredJobs.map((job, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                variant="outlined"
                sx={{ borderRadius: '10px', height: '100%', position: 'relative' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Typography variant="h6" fontFamily="gothamBold">
                    {job.vacancy_department}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: '#294992', fontFamily: 'gothamBold', cursor: 'pointer' }}
                    onClick={() => {
                      const formattedDepartment = formatForURL(job.vacancy_department);
                      const formattedPosition = formatPositionForURL(job.vacancy_title);
                      const path = `/careers/${formattedDepartment}/${formattedPosition}/${job.id}`;
                      navigate(path, { state: { id: job.id } });
                    }}>
                    {job.vacancy_title}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={1} mt={2}>
                    <AccessTime fontSize="small" color="action" />
                    <Typography variant="body2" fontFamily="gothamBook">
                      {job.vacancy_type}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1} mt={1}>
                    <LocationOn fontSize="small" color="action" />
                    <Typography variant="body2" fontFamily="gothamBook">
                      {job.vacancy_location}
                    </Typography>
                  </Box>
                </CardContent>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    backgroundColor: isMobile ? '#294992' : '#e7e7e7',
                    borderRadius: '50%',
                    padding: '2px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: !isMobile ? '#294992' : '#e7e7e7'
                    }
                  }}
                  onClick={() => {
                    const formattedDepartment = formatForURL(job.vacancy_department);
                    const formattedPosition = formatPositionForURL(job.vacancy_title);
                    const path = `/careers/${formattedDepartment}/${formattedPosition}/${job.id}`;
                    navigate(path, { state: { id: job.id } });
                  }}>
                  <EastRounded fontSize="medium" sx={{ color: '#FFFFFF' }} />
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography
            fontFamily="gothamBook"
            color="#00000099"
            fontSize="18px"
            mt={2}
            sx={{ width: '100%', textAlign: 'center' }}>
            No job openings match your filters.
          </Typography>
        )}
      </Grid>
    </Box>
  );
});

CareersOpenings.displayName = 'CareersOpenings';

export default CareersOpenings;
