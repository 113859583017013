import React, { useEffect } from 'react';
import { Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BranchList } from '../../constants/ContactUsConstant';
import { Person, LocationOn, PhoneIphone, Phone, Email } from '@mui/icons-material';
import ContactUsBg from '../../assets/Images/Contact-us-bg.png';

const BranchCard = ({ branch }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Card
      sx={{
        width: isSmallScreen ? '90%' : isMediumScreen ? 350 : 400,
        height: 'auto',
        margin: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 5,
        boxShadow: 4,
        padding: 0
      }}>
      <CardContent>
        <Typography
          gutterBottom
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'gothamBold',
            fontSize: isSmallScreen ? '14px' : '16px'
          }}>
          <span style={{ marginRight: 12, marginLeft: 12, color: 'red' }}>|</span>
          <span style={{ color: 'red' }}>KESARI SELECT</span>
          <span style={{ marginLeft: 4 }}>{branch.city}</span>
        </Typography>
        {branch.manager && (
          <Typography
            variant="body2"
            color="black"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'gothamBook',
              lineHeight: 2.1
            }}>
            <Person sx={{ marginRight: 1, color: 'black' }} /> {branch.manager}
          </Typography>
        )}
        {branch.address && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'gothamBook',
              textTransform: 'lowercase',
              lineHeight: 2.1
            }}>
            <LocationOn sx={{ marginRight: 1, color: 'text.secondary' }} /> {branch.address}
          </Typography>
        )}
        {branch.phone && (
          <Typography
            component="a"
            href={`tel:${branch.phone}`}
            variant="body2"
            color="black"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              fontFamily: 'gothamBook',
              fontWeight: 700,
              '&:hover': { color: 'red' },
              lineHeight: 2.1
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = 'red')}
            onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}>
            <PhoneIphone sx={{ marginRight: 1 }} /> {branch.phone}
          </Typography>
        )}
        {branch.landline && (
          <Typography
            component="a"
            href={`tel:${branch.landline}`}
            variant="body2"
            color="black"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              fontFamily: 'gothamBook',
              fontWeight: 700,
              '&:hover': { color: 'red' },
              lineHeight: 2.1
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = 'red')}
            onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}>
            <Phone sx={{ marginRight: 1 }} /> {branch.landline}
          </Typography>
        )}
        {branch.email && (
          <Typography
            component="a"
            href={`mailto:${branch.email}`}
            variant="body2"
            color="black"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              fontFamily: 'gothamBook',
              fontWeight: 700,
              '&:hover': { color: 'red' },
              lineHeight: 2
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = 'red')}
            onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}>
            <Email sx={{ marginRight: 1 }} /> {branch.email}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const Branches = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          marginBottom: 3,
          marginTop: 3,
          marginLeft: { xs: 2, sm: 5 },
          fontFamily: 'gothamBold',
          color: 'black'
        }}>
        Branches
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          backgroundImage: `url(${ContactUsBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: { xs: 1, sm: 1 }
        }}>
        {BranchList.map((branch, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            display="flex"
            sx={{ paddingTop: '0px !important' }}
            justifyContent="center">
            <BranchCard branch={branch} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Branches;
