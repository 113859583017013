import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
// import SelectChip from '../SelectChip';
import { holidayDurations } from '../../../constants/SelectedVacationSpotConstant';

const HolidayDuration = ({
  handleSelectVacationDays,
  handleTabChange,
  isSmallScreen,
  isTravclan
}) => (
  <Grid container spacing={1} sx={{ maxHeight: '100vh', overflowY: 'hidden' }}>
    {holidayDurations.map((item, index) => (
      <Grid item xs={6} sm={4} md={6} lg={6} key={index}>
        <Card
          className="holiday-card"
          sx={{
            position: 'relative',
            overflow: 'visible',
            '&:hover .MuiChip-root': {
              opacity: isSmallScreen ? 0 : 1
            },
            '&:hover .holiday-card-image': {
              transform: !isSmallScreen ? 'scale(1.05)' : 'none',
              transition: 'transform 0.3s ease'
            }
          }}>
          {/* <SelectChip
            className="select-chip"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              opacity: 0,
              transition: '0.3s all ease-out'
            }}
          /> */}
          <CardActionArea
            onClick={() => {
              handleSelectVacationDays(item.days);
              handleTabChange(isTravclan ? 2 : 3);
            }}>
            <CardMedia
              component="img"
              height={isSmallScreen ? 140 : 200}
              image={item.image}
              alt={item.days}
              sx={{
                objectFit: 'fill',
                borderRadius: '8px 8px 0px 0px',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: !isSmallScreen ? 'scale(1.05)' : 'none'
                }
              }}
            />
            <CardContent className="holiday-card-content">
              <Typography className="holiday-card-content-title">{item.days}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export default HolidayDuration;
