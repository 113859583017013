import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import BtnIcon from '../../../assets/common/btn_icon.svg';
import './PackageCustomizeTourButton.css';
import { cleanUrlString } from '../../../Helper/global';

const PackageCustomizeTourButton = ({
  packageDetails,
  isSticky,
  isNewSouthWale,
  isQueensland,
  currentPathName
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isTravclan, setIsTravclan] = useState(false);
  const [packageType, setPackageType] = useState('Premium');

  useEffect(() => {
    if (packageDetails) {
      const travclanFlag = packageDetails?.travclan_code ? true : false;
      setIsTravclan(travclanFlag);
      setLoading(false);
    }
  }, [packageDetails]);

  const handleEnquiryNow = () => {
    const countryName =
      packageDetails?.country === 'India' ? packageDetails?.state : packageDetails?.country;
    const packageId = packageDetails?.new_item_code;
    const numberOfNights = packageDetails?.number_of_nights;
    const travclanCode = packageDetails?.travclan_code;
    const travclanCodeFour = packageDetails?.travclan_code_four;
    const travclanCodeFive = packageDetails?.travclan_code_five;

    sessionStorage.setItem(
      'tripDetails',
      JSON.stringify({
        countryName: countryName,
        packageId: packageId,
        numberOfNights: numberOfNights,
        travclanCode: travclanCode,
        travclanCodeFour: travclanCodeFour,
        travclanCodeFive: travclanCodeFive,
        packageType: packageType
      })
    );

    // let navigatePath = currentPathName + '/vacation-spot';
    // if (isNewSouthWale) {
    //   navigatePath = '/destination/new-south-wales/vacation-spot';
    // } else if (isQueensland) {
    //   navigatePath = '/destination/queensland/vacation-spot';
    // }

    let navigatePath = currentPathName + '/vacation-spot';
    if (isNewSouthWale) {
      navigatePath = `/destination/new-south-wales/${cleanUrlString(
        packageDetails?.package_name
      )}/vacation-spot`;
    } else if (isQueensland) {
      navigatePath = `/destination/queensland/${cleanUrlString(
        packageDetails?.package_name
      )}/vacation-spot`;
    }
    navigate(navigatePath, {
      state: {
        packageId: packageId,
        selectedTour: countryName,
        isTravclan: isTravclan,
        packageType: packageType
      }
    });
  };

  return (
    <Box className="customize-tour-container">
      {isTravclan && (
        <Box className="toggle-group-container" mt={2} mb={2}>
          <ToggleButtonGroup
            value={packageType}
            exclusive
            onChange={(event, newType) => {
              if (newType !== null) setPackageType(newType);
            }}
            aria-label="package type"
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <ToggleButton
              value="Premium"
              sx={{
                backgroundColor: packageType === 'Premium' ? '#D4AF37 !important' : '#f5f5f5',
                color: packageType === 'Premium' ? '#fff !important' : '#555',
                fontWeight: 'bold',
                width: '100%',
                borderRadius: '20px',
                margin: '0 5px',
                textTransform: 'capitalize'
              }}>
              Premium
            </ToggleButton>
            <ToggleButton
              value="Superior"
              sx={{
                backgroundColor: packageType === 'Superior' ? '#C0C0C0 !important' : '#f5f5f5',
                color: packageType === 'Superior' ? '#fff !important' : '#555',
                fontWeight: 'bold',
                width: '100%',
                borderRadius: '20px',
                margin: '0 5px',
                textTransform: 'capitalize'
              }}>
              Superior
            </ToggleButton>
            <ToggleButton
              value="Budget"
              sx={{
                backgroundColor: packageType === 'Budget' ? '#27306B !important' : '#f5f5f5',
                color: packageType === 'Budget' ? '#fff !important' : '#555',
                fontWeight: 'bold',
                width: '100%',
                borderRadius: '20px',
                margin: '0 5px',
                textTransform: 'capitalize'
              }}>
              Budget
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Button
        className={`customize-tour-footer-btn ${isSticky ? 'btn-sticky' : 'btn-none-sticky'}`}
        variant="contained"
        onClick={handleEnquiryNow}
        sx={{
          backgroundColor: isTravclan && '#00B277 !important'
        }}
        disabled={loading}>
        <Box className="btn-with-text">
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <>
              <Box className="btn-text">{isTravclan ? 'Instant Quote' : 'Enquire Now'}</Box>
              <img src={BtnIcon} alt="" />
            </>
          )}
        </Box>
      </Button>
    </Box>
  );
};

export default PackageCustomizeTourButton;
