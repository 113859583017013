import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const CircularProgressWithLabel = ({ value, size = 50 }) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress
      variant="determinate"
      color="inherit"
      value={value}
      size={size}
      thickness={2}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Typography variant="caption" component="div" color="white">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

export default CircularProgressWithLabel;
