import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { PhotoData } from '../../constants/SouthWalesConstant';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
import SouthWalePhotoImg from '../../assets/Images/southWales/south-wales-photo.jpg';
import SouthWalePhotoMobileBannerImg from '../../assets/Images/southWales/south-wales-photo-mobile-banner.jpg';
import SouthWalesHomeSlider from '../../components/SouthWalesHome/SouthWalesHomeSlide/SouthWalesHomeSlider';
import SouthWalesPhotoComponent from '../../components/SouthWalesHome/SouthWalesPhotoGallery/SouthWalesPhotoGallery';
// import GetInTouch from '../../components/SouthWalesHome/GetInTouch';

const SouthWalesPhotoPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      <SouthWalesHomeSlider
        bannerImg={isMobile ? SouthWalePhotoMobileBannerImg : SouthWalePhotoImg}
      />
      <SouthWalesPhotoComponent photos={PhotoData} />
      {/* <GetInTouch /> */}
    </>
  );
};

export default SouthWalesPhotoPage;
