import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { PhotoData } from '../../constants/QueenslandConstant';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
import PhotoGalleryBanner from '../../assets/Images/queensland/Queensland-photo-gallery-banner.jpg';
import PhotoGalleryMobileBanner from '../../assets/Images/queensland/Queensland-photo-gallery-mobile-banner.jpg';
import QueenslandHomeBanner from '../../components/QueenslandComponents/QueenslandHomeBanner';
import QueenslandPhotoGallery from '../../components/QueenslandComponents/QueenslandPhotoGallery';
// import QueenslandGetInTouch from '../../components/QueenslandComponents/QueenslandGetInTouch';

const QueenslandPhotoPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      <QueenslandHomeBanner bannerImg={isMobile ? PhotoGalleryMobileBanner : PhotoGalleryBanner} />
      <QueenslandPhotoGallery photos={PhotoData} />
      {/* <QueenslandGetInTouch /> */}
    </>
  );
};

export default QueenslandPhotoPage;
