import React from 'react';
import ReactPlayer from 'react-player';
import { Box, Typography, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const VideoPlayer = ({ videoUrl, videoTitle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNewSouthWaleVideoPage =
    location?.pathname === '/destination/new-south-wales/video-gallery';

  const handleTitleClick = () => {
    if (!isNewSouthWaleVideoPage) {
      navigate('/destination/new-south-wales/video-gallery');
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        pr: !isNewSouthWaleVideoPage && 2,
        maxWidth: 800,
        margin: 'auto',
        backgroundColor: 'transparent'
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: '10px',
          height: { xs: '200px', sm: '250px', md: '300px', lg: '316px' }
        }}>
        <ReactPlayer url={videoUrl} controls width="100%" height="100%" />
      </Box>
      <Typography
        variant="h6"
        mt={1}
        onClick={handleTitleClick}
        sx={{
          cursor: !isNewSouthWaleVideoPage && 'pointer',
          fontFamily: 'gothamBold',
          textAlign: 'center'
        }}
        gutterBottom>
        {videoTitle}
      </Typography>
    </Paper>
  );
};

export default VideoPlayer;
