/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './HomeSlider.css';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { PUBLIC_CMS_URL, IMAGE_DIR_PATH, BASE_URL, API_URL } from '../../../APIs/endpoint';
import Slider from 'react-slick';
import { getUrlAfterDomain } from '../../../Helper/global';
import VideoModal from '../../VideoModal/VideoModal';
import Cookies from 'js-cookie';
import { api } from '../../../APIs/api';
import { toast } from 'react-toastify';

const HomeSliderComponent = ({ timeLineSliderData, isLoader }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isVideoModal, setIsVideoModal] = useState(false);

  const handleOpen = () => {
    setIsVideoModal(true);
  };
  const handleClose = () => {
    Cookies.set('isVideoModalOpened', 'true');
    setIsVideoModal(false);
  };

  useEffect(() => {
    const preloadImage = new Image();
    if (timeLineSliderData && timeLineSliderData.length > 0) {
      preloadImage.src = `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${timeLineSliderData[0].timeline_image}.webp`;
    }
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'style';
    link.href = './HomeSlider.css';
    link.onload = () => (link.rel = 'stylesheet');
    document.head.appendChild(link);
  }, [timeLineSliderData]);

  useEffect(() => {
    const isModalOpened = Cookies.get('isVideoModalOpened');
    if (!isModalOpened) {
      handleOpen();
    }
  }, []);

  const settings = {
    dots: true,
    fade: false,
    infinite: true,
    arrows: true,
    autoplay: !isLoader,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleImageClick = async (url, timelineId) => {
    try {
      await api.post(`${BASE_URL}${API_URL.TIMELINE_SLIDER_TRACK}`, { timeline_id: timelineId });
      navigate(getUrlAfterDomain(url));
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
    }
  };

  return (
    <Box
      className="slider-container"
      sx={{ maxHeight: { xs: 252, sm: 500, md: 470, lg: 639 }, content: '""' }}>
      {isLoader ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: { xs: '100%', sm: '100%', md: 1000, lg: 1399, xl: 1900 },
            height: { xs: 252, sm: 500, md: 470, lg: 639 }
          }}
        />
      ) : (
        <>
          {/* <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'none'
            }}>
            <Box sx={{ position: 'absolute', top: 10, left: 0, zIndex: 20 }}>
              <VideoModal open={isVideoModal} handleClose={handleClose} />
            </Box>
          </Box> */}
          <Slider className="parent_component" {...settings}>
            {timeLineSliderData
              ?.sort((a, b) => a.timelineslider_sequence_no - b.timelineslider_sequence_no)
              .map((item, i) => {
                const imageSrc = isMobile
                  ? `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.mobile_timeline_image}` // Mobile image
                  : `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.timeline_image}`; // Desktop image
                return (
                  <Box
                    key={i}
                    className="img_section"
                    onClick={() => handleImageClick(item.timeline_url, item.timeline_id)}
                    sx={{ cursor: 'pointer' }}>
                    <img
                      loading={i === 0 ? 'eager' : 'lazy'}
                      width={1500}
                      height={570}
                      srcSet={`
                    ${imageSrc}?w=300 300w,
                    ${imageSrc}?w=768 768w,
                    ${imageSrc}?w=1024 1024w
                `}
                      sizes="(max-width: 600px) 300px, (max-width: 1024px) 768px, 1024px"
                      src={imageSrc}
                      alt={`Timeline slide ${i + 1}`}
                    />
                  </Box>
                );
              })}
          </Slider>
        </>
      )}
    </Box>
  );
};

export default React.memo(HomeSliderComponent);
