import React, { useEffect, useState, useRef } from 'react';
import '../SouthWaleHeader/SouthWaleHeader.css';
import {
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { CloseRounded, Search } from '@mui/icons-material';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { cleanUrlString } from '../../Helper/global';

const StyledTextField = styled(TextField)(() => ({
  width: '100% !important',
  backgroundColor: 'white',
  borderRadius: '20px',
  height: '40px',
  border: 'none',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '9.5px 1px !important'
  }
}));

const CustomTypography = (props) => <Typography {...props} sx={{ fontFamily: 'inherit' }} />;

const DropdownList = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '90%',
  width: '100%',
  maxHeight: '200px',
  overflowY: 'auto',
  zIndex: 9999,
  marginTop: theme.spacing(1),
  borderRadius: '10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.paper
}));

const StyledListItem = styled(ListItem)({
  padding: '8px 16px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  '&:last-child': {
    borderBottom: 'none'
  }
});

const SearchDestination = () => {
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const getSearchResults = async () => {
    try {
      const searchRes = await api.get(
        `${BASE_URL}${API_URL.GET_QUEENSLAND_PACKAGE_LIST}?package_name=${searchText}`
      );
      const { status, data } = searchRes;
      if (status) {
        const dropdownOptions = data.map((item) => {
          const packageId = item?.new_item_code;
          const packageName = item?.package_name;
          return { packageId, packageName };
        });
        setSearchData(dropdownOptions);
        setShowDropdown(true);
      } else {
        toast.error(data, { autoClose: 3000 });
        setShowDropdown(false);
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (searchText && searchText.length > 2) {
      getSearchResults();
    } else {
      setShowDropdown(false);
    }
  }, [searchText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClearSearch();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLocationClick = (packageId, packageTitle) => {
    let url;
    url = `/destination/queensland/${cleanUrlString(packageTitle)}`;
    navigate(url, { state: { packageId: packageId }, replace: true });
    handleClearSearch();
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchData([]);
  };

  return (
    <Grid item xs={7} sm={4} md={4} lg={4} xl={6} className="queensland-search-box">
      <StyledTextField
        autoComplete="off"
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Search Queensland Packages"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">{/* <Search /> */}</InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              {showDropdown && searchData.length > 0 ? (
                <IconButton onClick={handleClearSearch}>
                  <CloseRounded sx={{ cursor: 'pointer' }} />
                </IconButton>
              ) : (
                <Search />
              )}
            </InputAdornment>
          )
        }}
      />
      {showDropdown && searchData.length > 0 && (
        <DropdownList ref={dropdownRef}>
          <List>
            {searchData.map((item, index) => (
              <StyledListItem
                button
                key={index}
                onClick={() => handleLocationClick(item?.packageId, item?.packageName)}>
                <ListItemText
                  primary={item?.packageName}
                  primaryTypographyProps={{ component: CustomTypography }}
                />
              </StyledListItem>
            ))}
          </List>
        </DropdownList>
      )}
    </Grid>
  );
};

export default SearchDestination;
