import React, { useEffect, useState } from 'react';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
import QueenslandHomeBanner from '../../components/QueenslandComponents/QueenslandHomeBanner';
import BlogsBannerImg from '../../assets/Images/queensland/Queesland-blog-banner.jpg';
import BlogsMobileBannerImg from '../../assets/Images/queensland/Queesland-blog-mobile-banner.jpg';
// import QueenslandGetInTouch from '../../components/QueenslandComponents/QueenslandGetInTouch';
import QueenslandBlogCard from '../../components/QueenslandComponents/QueenslandHolidayIdeas/QueenslandBlogCard';
import QueenslandBlogCardSkeleton from '../../components/Loader/QueenslandBlogCardSkeleton';

const QueenslandBlogsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [isLoader, setIsLoader] = useState(false);
  const [queenslandBlogsList, setQueenslandBlogsList] = useState([]);

  const getQueenslandBlogListApi = async () => {
    try {
      setIsLoader(true);
      const blogListRes = await api.get(`${BASE_URL}${API_URL.GET_QUEENSLAND_BLOG_LIST}`);
      const { data, message, status } = blogListRes;
      if (status) {
        setQueenslandBlogsList(data);
      } else {
        toast.error(message, { autoClose: 2000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 2000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getQueenslandBlogListApi();
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      <QueenslandHomeBanner bannerImg={isMobile ? BlogsMobileBannerImg : BlogsBannerImg} />

      <Box
        sx={{
          position: 'relative',
          maxWidth: '1900px',
          margin: { sm: '0 auto', lg: '0 32px' },
          overflow: 'hidden'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2 }}>
          <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '22px' },
              fontFamily: 'gothamBold',
              textAlign: 'center',
              color: '#27306B'
            }}>
            QUEENSLAND HOLIDAY IDEAS
          </Typography>
          <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
        </Box>
        <Box sx={{ px: { xs: 2, sm: 4, md: 6 } }}>
          <Grid container spacing={2}>
            {isLoader
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <QueenslandBlogCardSkeleton />
                  </Grid>
                ))
              : queenslandBlogsList.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <QueenslandBlogCard blogData={item} isLoader={isLoader} />
                  </Grid>
                ))}
          </Grid>
        </Box>
      </Box>
      {/* <QueenslandGetInTouch /> */}
    </>
  );
};

export default QueenslandBlogsPage;
