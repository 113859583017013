import React, { forwardRef } from 'react';
import './ThemedEscapes.css';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  styled,
  Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_CMS_URL, IMAGE_DIR_PATH } from '../../../APIs/endpoint';
import { convertCommaToAnd } from '../../../Helper/global';
import CustomButton from '../../common/Button';
import ThemedCardSkeleton from '../../Loader/ThemedCardSkeleton';

// eslint-disable-next-line no-unused-vars
const RootCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none !important',
  '&:hover .MuiCardActionArea-focusHighlight': {
    opacity: '0 !important'
  }
}));

const ThemedEscapes = forwardRef(({ themeData, isLoader }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handlePackageThemed = (selectedTheme) => {
    navigate(`/explore/theme/${convertCommaToAnd(selectedTheme)}`, {
      state: {
        themeFilterData: themeData.map((obj) => ({
          label: obj.theme,
          value: obj.theme,
          isSelected: obj.theme === selectedTheme
        }))
      }
    });
  };

  return (
    <Container className="themeEscape_main" ref={ref} sx={{ paddingX: { xs: '0px', md: '16px' } }}>
      <Box className="themeEscape_heading" sx={{ height: '100%' }}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center'
          }}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box className="themeEscape_heading_title">
              <Typography>Select Themed Escapes: Tailored for Everyone.</Typography>
              {/* <Typography>Tailored for Everyone.</Typography> */}
            </Box>
            {/* <Typography className="themeEscape_heading_desc" mt={1}>
                Discover your reason, make memories, and embark on your adventure.
              </Typography> */}
          </Grid>
        </Grid>
      </Box>
      <Box className="themedEscapes_places">
        <Box
          className="themedEscapes_places_inner"
          sx={{
            paddingTop: '10.4px',
            overflowX: { xs: 'auto', lg: 'unset' },
            overflowY: { xs: 'hidden' },
            display: 'flex',
            justifyContent: 'center',
            width: { xs: '600px', sm: '100%' }
          }}>
          {themeData && Object.keys(themeData).length > 0 ? (
            isLoader ? (
              <ThemedCardSkeleton />
            ) : (
              <Grid
                container
                spacing={isMobile ? 0 : 6}
                gap={isMobile ? 2 : 0}
                justifyContent={{ xs: 'left', md: 'center' }}
                sx={{
                  overflowY: { xs: 'hidden', lg: 'unset' },
                  paddingLeft: { xs: '5px' },
                  width: '100%'
                }}>
                {themeData?.map((item, i) => (
                  <Grid
                    item
                    xs={2}
                    sm={4}
                    md={4}
                    lg={2.4}
                    key={i}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      spacing: { xs: '50px !important', lg: 20 }
                    }}>
                    <RootCard
                      sx={{
                        display: { xs: 'block' },
                        width: { xs: 114, sm: 200, md: 250, lg: 280 },
                        marginTop: 5,
                        overflow: 'unset'
                      }}>
                      <CardActionArea
                        onClick={() => handlePackageThemed(item.theme)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          '&:hover img': {
                            borderRadius: 6,
                            transform: 'scale(1.05)'
                          }
                        }}>
                        {isLoader ? (
                          <Skeleton variant="circular" width={175} height={175} />
                        ) : (
                          <CardMedia
                            sx={{
                              width: { xs: 100, sm: 174, md: 195, lg: 184 },
                              height: { xs: 100, sm: 174, md: 195, lg: 184 },
                              textAlign: 'center',
                              marginTop: '-12px',
                              borderRadius: 30,
                              transition:
                                'transform 0.3s ease-in-out, border-radius 0.3s ease-in-out'
                            }}
                            component="img"
                            height="140"
                            image={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.THEME_PATH}${item.theme_image}`}
                            alt="Image"
                          />
                        )}
                        <CardContent>
                          <Typography
                            className="themeEscape_card_placeName"
                            sx={{
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                                md: '20px',
                                lg: '20px',
                                xl: '22px'
                              }
                            }}>
                            {isLoader ? (
                              <Skeleton variant="text" width={100} height={50} />
                            ) : (
                              <>{item.theme}</>
                            )}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </RootCard>
                  </Grid>
                ))}
              </Grid>
            )
          ) : (
            <Box
              className="themeEscape_heading"
              sx={{ height: themeData?.length === 0 ? '200px' : '100%', alignContent: 'center' }}>
              <Grid className="themeEscape_heading_coming_soon" container>
                <Grid item>
                  <Box className="themeEscape_heading_title">
                    <Typography>Coming Soon...!</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="themeEscape_btn_container">
        <CustomButton
          className="themeEscape_typo_btn"
          onClick={() => navigate('/explore/theme')}
          showArrow={true}>
          <Typography fontFamily="gothamBold">Explore Trips</Typography>
        </CustomButton>
      </Box>
    </Container>
  );
});

ThemedEscapes.displayName = 'ThemedEscapes';

export default ThemedEscapes;
