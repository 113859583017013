import React from 'react';
import ReactPlayer from 'react-player';
import { Box, Typography, Paper } from '@mui/material';

const QueenslandVideoPlayer = ({ videoUrl, videoTitle }) => (
  <Paper
    elevation={3}
    sx={{
      pr: 2,
      maxWidth: 800,
      margin: 'auto',
      backgroundColor: 'transparent'
    }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: '10px',
        height: { xs: '200px', sm: '250px', md: '300px', lg: '316px' }
      }}>
      <ReactPlayer url={videoUrl} controls width="100%" height="100%" />
    </Box>
    <Typography
      variant="h6"
      mt={1}
      sx={{
        fontFamily: 'gothamBold',
        textAlign: 'center'
      }}
      gutterBottom>
      {videoTitle}
    </Typography>
  </Paper>
);

export default QueenslandVideoPlayer;
