import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { cleanUrlString } from '../../../Helper/global';
import BlogCardCarouselHeader from './BlogCardCarouselHeader';

const QueenslandBlogCard = ({ blogData, isLoader }) => {
  const navigate = useNavigate();

  return (
    <Box
      mb={3}
      width={{ xs: '96%', md: '92%' }}
      boxShadow="10px 10px 30px 0px #37444A29"
      backgroundColor="#FFFFFF"
      borderRadius="10px"
      overflow="hidden">
      <Box width={{ xs: '100%', md: '100%' }} height="170" position="relative">
        <BlogCardCarouselHeader blogData={blogData} isLoader={isLoader} />
      </Box>
      <Box>
        <Box px={{ xs: 2.5, md: '12px' }} pb={0} pt={{ xs: '18px', md: '8px' }}>
          <Typography
            fontFamily="gothamBold"
            fontSize={{ xs: 14, md: 18 }}
            textAlign="center"
            lineHeight={{ xs: '22px', md: '32px' }}
            whiteSpace="normal"
            component="h2"
            minHeight={{ xs: '44px', md: '64px' }}>
            {isLoader ? (
              <Skeleton variant="text" width="80%" height={{ xs: 44, md: 64 }} />
            ) : (
              blogData?.blog_title
            )}
          </Typography>
        </Box>
        <Box bgcolor="#f9f9fb" pt={0} pr={3} pb={3} pl={3} minWidth="168px">
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={30} />
          ) : (
            <Box display="flex" flexWrap="wrap" alignItems="center" color="#000000CC" width="100%">
              <Typography
                component="div"
                fontFamily="gothamBook"
                fontSize="14px"
                lineHeight="24px"
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxHeight: '130px'
                }}
                dangerouslySetInnerHTML={{ __html: blogData?.blog_description }}
              />
            </Box>
          )}
          <Box
            className="btn-container"
            mt="20px"
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="center"
            width="100%">
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/destination/queensland/blog/${cleanUrlString(blogData?.blog_slug)}`, {
                  state: { blogSlug: blogData?.blog_slug }
                })
              }
              sx={{
                bgcolor: '#27306B',
                color: '#fff',
                border: '1px solid #0000004D',
                borderRadius: '10px',
                fontFamily: 'gothamBook',
                fontSize: '14px',
                lineHeight: '28px',
                padding: '11px 10px',
                textAlign: 'center',
                textTransform: 'capitalize',
                maxHeight: '46px',
                ':hover': {
                  color: '#fff',
                  bgcolor: '#27306B'
                }
              }}>
              Read more
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QueenslandBlogCard;
