import React, { useState, useEffect, forwardRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PackageCardSkeleton from '../../Loader/PackageCardSekeleton';
import SouthWalesPackageCard from './SouthWalesPackageCard';

const ExploreSouthWales = forwardRef(
  ({ southWalsPackageList, isLoader, selectedCurrency }, ref) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [slider, setSlider] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [clickDisabled, setClickDisabled] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(3);
    const southWalesDataLength = southWalsPackageList.length;

    useEffect(() => {
      const updateSlidesToShow = () => {
        const width = window.innerWidth;
        if (width < 600) setSlidesToShow(1.1);
        else if (width < 960) setSlidesToShow(2);
        else setSlidesToShow(3);
      };
      updateSlidesToShow();
      window.addEventListener('resize', updateSlidesToShow);
      return () => window.removeEventListener('resize', updateSlidesToShow);
    }, []);

    const goToPrev = () => {
      if (!clickDisabled && slider && currentIndex > 0) {
        setClickDisabled(true);
        slider.slickPrev();
        setTimeout(() => setClickDisabled(false), 500);
      }
    };

    const goToNext = () => {
      if (!clickDisabled && slider && currentIndex < southWalesDataLength - slidesToShow) {
        setClickDisabled(true);
        slider.slickNext();
        setTimeout(() => setClickDisabled(false), 500);
      }
    };

    const settings = {
      dots: false,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      infinite: false,
      beforeChange: (_, next) => setCurrentIndex(next),
      responsive: [
        { breakpoint: 1200, settings: { slidesToShow: 3 } },
        { breakpoint: 992, settings: { slidesToShow: 2 } },
        { breakpoint: 768, settings: { slidesToShow: 1.1 } }
      ]
    };

    return (
      <Box
        ref={ref}
        sx={{
          position: 'relative',
          maxWidth: '1900px',
          margin: { sm: '0 auto', lg: '0 32px' },
          overflow: 'hidden'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2 }}>
          <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '22px' },
              fontFamily: 'gothamBold',
              textAlign: 'center',
              color: '#27306B'
            }}>
            EXPLORE NEW SOUTH WALES
          </Typography>
          <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
        </Box>
        {!isSmallScreen && (
          <ChevronLeft
            onClick={goToPrev}
            sx={{
              width: '20px',
              height: '20px',
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              fontSize: '2rem',
              cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
              color: '#fff',
              backgroundColor: currentIndex === 0 ? '#ddd' : '#27306B',
              opacity: currentIndex === 0 ? 0.8 : 1,
              borderRadius: '50%',
              padding: '10px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease-in-out'
            }}
          />
        )}

        <Box
          sx={{
            pl: { xs: '15px', sm: '10px', md: 'calc(10% - 65px)' },
            pr: { md: 'calc(10% - 81px)' }
          }}>
          <Slider
            key={southWalesDataLength}
            {...settings}
            ref={(slider) => setSlider(slider)}
            className="card-list">
            {isLoader
              ? Array.from({ length: slidesToShow }).map((_, index) => (
                  <Box key={index}>
                    <PackageCardSkeleton />
                  </Box>
                ))
              : southWalsPackageList.map((item, index) => (
                  <Box key={index}>
                    <SouthWalesPackageCard
                      tourPackage={item}
                      isLoader={isLoader}
                      selectedCurrency={selectedCurrency}
                    />
                  </Box>
                ))}
          </Slider>
        </Box>
        {!isSmallScreen && (
          <ChevronRight
            onClick={goToNext}
            sx={{
              width: '20px',
              height: '20px',
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              fontSize: '2rem',
              cursor:
                currentIndex >= southWalesDataLength - slidesToShow ? 'not-allowed' : 'pointer',
              color: '#fff',
              backgroundColor:
                currentIndex >= southWalesDataLength - slidesToShow ? '#ddd' : '#27306B',
              opacity: currentIndex >= southWalesDataLength - slidesToShow ? 0.8 : 1,
              borderRadius: '50%',
              padding: '10px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease-in-out'
            }}
          />
        )}
      </Box>
    );
  }
);

ExploreSouthWales.displayName = 'CutomisedHolidays';

export default ExploreSouthWales;
