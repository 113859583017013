import React, { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useUTMSource } from '../context/UTMSourceProvider';
import { MainLayout } from '../layout/MainLayout';
import { Authlayout } from '../layout/Authlayout';
import Home from '../pages/HomeMain';
import PackageDetails from '../pages/PackageDetails';
import PackageListing from '../pages/PackageListing';
import ProductReview from '../pages/ProductReview/ProductReview';
import ReviewDetails from '../pages/ReviewDetails';
import ReviewPay from '../components/ReviewPay/ReviewPay';
import ReviewAndPay from '../pages/ReviewAndPay';
import Payment from '../pages/payment/payment';
import PhotoGallery from '../pages/PhotoGallery/PhotoGallery';
import TripRequestConfirmation from '../pages/TripRequestConfirmation';
import Conversational from '../pages/Conversational';
import SelectedVacationSpot from '../components/conversational/SelectedVacationSpot/SelectedVacationSpot';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndCondition from '../pages/TermsAndCondition';
import NotFound404 from '../pages/NotFound404';
import Careers from '../pages/Careers/Careers';
import JobDetails from '../components/Careers/JobDetails/JobDetails';
import ConstactUs from '../pages/ContactUs';
import NewSouthWaleLanding from '../pages/SouthWalesPages/SouthWalesHomeMain';
import SouthWalesPackages from '../pages/SouthWalesPages/SouthWalesPackages';
import SouthWalesVideoPage from '../pages/SouthWalesPages/SouthWalesVideoPage';
import SouthWalesPhotoPage from '../pages/SouthWalesPages/SouthWalesPhotoPage';
import QueenslandHomeMain from '../pages/QueenslandPages/QueenslandHomeMain';
import QueenslandPackages from '../pages/QueenslandPages/QueenslandPackages';
import QueenslandPhotoPage from '../pages/QueenslandPages/QueenslandPhotoPage';
import QueenslandVideoPage from '../pages/QueenslandPages/QueenslandVideoPage';
import QueenslandBlogsPage from '../pages/QueenslandPages/QueenslandBlogsPage';
import BlogDetailsPage from '../pages/QueenslandPages/BlogDetailsPage';
import QueenslandGroupTours from '../pages/QueenslandPages/QueenslandGroupTours';

const authRoutes = [
  { path: '/', element: <Home /> },
  { path: '/product-review', element: <ProductReview /> },
  { path: '/explore/*', element: <PackageListing /> },
  {
    path: ':zone/:continent/:country/:state/:package-id',
    element: <PackageDetails />
  },
  {
    path: '/explore/:package-id',
    element: <PackageDetails />
  },
  { path: '/review-details', element: <ReviewDetails /> },
  { path: '/review-pay', element: <ReviewPay /> },
  { path: '/conversational', element: <Conversational /> },
  {
    path: ':zone/:continent/:country/:state/:package-id/vacation-spot',
    element: <SelectedVacationSpot />
  },
  { path: '/explore/:package-id/vacation-spot', element: <SelectedVacationSpot /> },
  { path: '/review-pay', element: <ReviewAndPay /> },
  { path: '/payment', element: <Payment /> },
  { path: '/photo-gallery', element: <PhotoGallery /> },
  {
    path: ':zone/:continent/:country/:state/:package-id/trip-confirmation',
    element: <TripRequestConfirmation />
  },
  {
    path: '/explore/:package-id/trip-confirmation',
    element: <TripRequestConfirmation />
  },
  { path: '/destination/new-south-wales', element: <NewSouthWaleLanding /> },
  { path: '/destination/new-south-wales/packages', element: <SouthWalesPackages /> },
  { path: '/destination/new-south-wales/:package-id', element: <PackageDetails /> },
  {
    path: '/destination/new-south-wales/:package-id/vacation-spot',
    element: <SelectedVacationSpot />
  },
  { path: '/destination/new-south-wales/video-gallery', element: <SouthWalesVideoPage /> },
  { path: '/destination/new-south-wales/photo-gallery', element: <SouthWalesPhotoPage /> },
  {
    path: '/destination/new-south-wales/:package-id/trip-confirmation',
    element: <TripRequestConfirmation />
  },

  { path: '/destination/queensland', element: <QueenslandHomeMain /> },
  { path: '/destination/queensland/packages', element: <QueenslandPackages /> },
  { path: '/destination/queensland/:package-id', element: <PackageDetails /> },
  { path: '/destination/queensland/:package-id/vacation-spot', element: <SelectedVacationSpot /> },
  { path: '/destination/queensland/video-gallery', element: <QueenslandVideoPage /> },
  { path: '/destination/queensland/photo-gallery', element: <QueenslandPhotoPage /> },
  { path: '/destination/queensland/blog-posts', element: <QueenslandBlogsPage /> },
  { path: '/destination/queensland/blog/:blog-slug', element: <BlogDetailsPage /> },
  { path: '/destination/queensland/group-tours', element: <QueenslandGroupTours /> },
  {
    path: '/destination/queensland/:package-id/trip-confirmation',
    element: <TripRequestConfirmation />
  }
];

// Custom hook for UTM persistence in navigation
const useUTMPersistentNavigate = () => {
  const navigate = useNavigate();
  const utmSource = useUTMSource();
  return (to, options) => {
    if (typeof to === 'string' && utmSource) {
      const url = new URL(to, window.location.origin);
      const params = new URLSearchParams(url.search);
      if (!params.get('utm_source')) {
        params.set('utm_source', utmSource);
        to = `${url.pathname}?${params.toString()}`;
      }
    }
    navigate(to, options);
  };
};

// Component to append utm_source dynamically
const RouteWithUTMSource = ({ element, utmSource, navigate }) => {
  useEffect(() => {
    if (utmSource) {
      const currentPathname = window.location.pathname;
      const currentSearchParams = new URLSearchParams(window.location.search);

      if (!currentSearchParams.get('utm_source')) {
        currentSearchParams.set('utm_source', utmSource);
        const updatedUrl = `${currentPathname}?${currentSearchParams.toString()}`;

        if (window.location.href !== window.location.origin + updatedUrl) {
          navigate(updatedUrl, { replace: true });
        }
      }
    }
  }, [utmSource, navigate]);

  return element;
};

// Recursive function to append UTM handling to all routes
const appendUTMSourceToRoutes = (routes, utmSource, navigate) =>
  routes.map((route) => {
    if (route.path === '/vacation-spot') {
      return { ...route };
    }
    return {
      ...route,
      element: (
        <RouteWithUTMSource element={route.element} utmSource={utmSource} navigate={navigate} />
      ),
      children: route.children
        ? appendUTMSourceToRoutes(route.children, utmSource, navigate)
        : undefined
    };
  });

const Routes = () => {
  const utmSource = useUTMSource();
  const navigate = useUTMPersistentNavigate();

  const enhancedRoutes = appendUTMSourceToRoutes(
    [
      {
        path: '/',
        element: <Authlayout />,
        children: authRoutes
      },
      {
        path: '/auth',
        element: <MainLayout />,
        children: []
      },
      {
        path: '/termsncondition',
        element: <TermsAndCondition />
      },
      {
        path: '/careers/:department/:position/:id',
        element: <JobDetails />
      },
      {
        path: '/careers',
        element: <Careers />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: '/contact-us',
        element: <ConstactUs />
      },
      { path: '*', element: <NotFound404 /> }
    ],
    utmSource,
    navigate
  );

  return useRoutes(enhancedRoutes);
};

export default Routes;
