import React, { useEffect, useRef, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { toast } from 'react-toastify';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
import HomeImg from '../../assets/Images/southWales/south-wales-home-banner.jpg';
import HomeMobileBannerImg from '../../assets/Images/southWales/south-wales-home-mobile-banner.jpg';
import PackageCardSkeleton from '../../components/Loader/PackageCardSekeleton';
import SouthWalesHomeSlider from '../../components/SouthWalesHome/SouthWalesHomeSlide/SouthWalesHomeSlider';
import SouthWalesPackageCard from '../../components/SouthWalesHome/ExploreSouthWales/SouthWalesPackageCard';
import SouthWaleHeader from '../../layout/SouthWaleHeader/SouthWaleHeader';

const SouthWalesPackages = () => {
  const customizedHolidayRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const homeMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [isLoader, setIsLoader] = useState(false);
  const [packageListData, setPackageListData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [slider, setSlider] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clickDisabled, setClickDisabled] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(3);

  // START: Get & Set the choose currency from session storage
  const updateSelectedCurrency = () => {
    const currency = sessionStorage.getItem('selectedCurrency');
    if (currency) setSelectedCurrency(currency);
  };

  useEffect(() => {
    updateSelectedCurrency();
    const interval = setInterval(updateSelectedCurrency, 1000);
    return () => clearInterval(interval);
  }, []);
  // END:  Get & Set the choose currency from session storage

  useEffect(() => {
    const updateSlidesToShow = () => {
      const width = window.innerWidth;
      if (width < 600) setSlidesToShow(1.1);
      else if (width < 960) setSlidesToShow(2);
      else setSlidesToShow(3);
    };

    window.addEventListener('resize', updateSlidesToShow);
    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, []);

  const goToPrev = () => {
    if (!clickDisabled && slider && currentIndex > 0) {
      setClickDisabled(true);
      slider.slickPrev();
      setTimeout(() => setClickDisabled(false), 500);
    }
  };

  const goToNext = () => {
    if (!clickDisabled && slider && currentIndex < packageListData?.length - slidesToShow) {
      setClickDisabled(true);
      slider.slickNext();
      setTimeout(() => setClickDisabled(false), 500);
    }
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: false,
    beforeChange: (_, next) => setCurrentIndex(next),
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1.1 } }
    ]
  };

  const getSouthWalesPackageListApi = async () => {
    try {
      setIsLoader(true);
      const travclanListRes = await api.get(`${BASE_URL}${API_URL.GET_SOUTH_WALES_PACKAGE_LIST}`);
      const { data, message, status } = travclanListRes;
      if (status) {
        setPackageListData(data);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSouthWalesPackageListApi();
  }, []);

  return (
    <>
      <HelmetProvider>
        <HelmetComponent metaData={homeMetaData} />
      </HelmetProvider>
      <SouthWaleHeader customizedHolidayRef={customizedHolidayRef} />
      <SouthWalesHomeSlider bannerImg={isMobile ? HomeMobileBannerImg : HomeImg} />
      <Box
        ref={customizedHolidayRef}
        sx={{
          position: 'relative',
          maxWidth: '1900px',
          margin: { sm: '0 auto', lg: '0 32px' },
          overflow: 'hidden'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2 }}>
          <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '22px' },
              fontFamily: 'gothamBold',
              textAlign: 'center',
              color: '#27306B'
            }}>
            EXPLORE NEW SOUTH WALES
          </Typography>
          <Box sx={{ flex: 1, height: '2px', backgroundColor: '#000', mx: 2 }} />
        </Box>
        {!isSmallScreen && (
          <ChevronLeft
            onClick={goToPrev}
            sx={{
              width: '20px',
              height: '20px',
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              fontSize: '2rem',
              cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
              color: '#fff',
              backgroundColor: currentIndex === 0 ? '#ddd' : '#27306B',
              opacity: currentIndex === 0 ? 0.8 : 1,
              borderRadius: '50%',
              padding: '10px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease-in-out'
            }}
          />
        )}

        <Box
          sx={{
            pl: { xs: '15px', sm: '10px', md: 'calc(10% - 65px)' },
            pr: { md: 'calc(10% - 81px)' }
          }}>
          <Slider {...settings} ref={(slider) => setSlider(slider)} className="card-list">
            {isLoader
              ? Array.from({ length: slidesToShow }).map((_, index) => (
                  <Box key={index}>
                    <PackageCardSkeleton />
                  </Box>
                ))
              : packageListData.map((item, index) => (
                  <Box key={index}>
                    <SouthWalesPackageCard
                      tourPackage={item}
                      isLoader={isLoader}
                      selectedCurrency={selectedCurrency}
                    />
                  </Box>
                ))}
          </Slider>
        </Box>
        {!isSmallScreen && (
          <ChevronRight
            onClick={goToNext}
            sx={{
              width: '20px',
              height: '20px',
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              fontSize: '2rem',
              cursor:
                currentIndex >= packageListData?.length - slidesToShow ? 'not-allowed' : 'pointer',
              color: '#fff',
              backgroundColor:
                currentIndex >= packageListData?.length - slidesToShow ? '#ddd' : '#27306B',
              opacity: currentIndex >= packageListData?.length - slidesToShow ? 0.8 : 1,
              borderRadius: '50%',
              padding: '10px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease-in-out'
            }}
          />
        )}
      </Box>
    </>
  );
};

export default SouthWalesPackages;
