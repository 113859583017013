/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Link
} from '@mui/material';
import { formatPrice } from '../../Helper/global';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import KesariArrowWhite from '../../assets/Images/Arrow-White-Red.png';

const TripRequestConfirmation = () => {
  const [isLoader, setIsLoader] = useState(false);
  const tripDetailsFromStorage = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
  //const quotationPrice = tripDetailsFromStorage?.quotationPrice;
  //const downloadUrl = tripDetailsFromStorage?.downloadUrl;
  const location = useLocation();
  const isTravclanError = location?.state?.isTravclanError ? true : false;

  const {
    packageId = 'N/A',
    countryName = 'N/A',
    quotationPrice = '',
    downloadUrl = '',
    tripDate = 'N/A',
    tripMembers = {},
    travclanCode = '',
    travclanCodeFour = '',
    travclanCodeFive = ''
  } = tripDetailsFromStorage;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getFileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  };

  const handleDownload = async () => {
    setIsLoader(true);
    try {
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = getFileNameFromUrl(downloadUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const handlePreview = async () => {
    setIsLoader(true);
    try {
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 5000);
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const tripDetails = {
    'Package Id:': packageId === 'N/A' ? 'N/A' : packageId,
    'Destination:': packageId === 'N/A' ? 'N/A' : countryName,
    'Departure Date:':
      tripDate === 'N/A'
        ? 'N/A'
        : new Date(tripDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
    "I'm flexible on the date:": 'Yes',
    'Number of nights:': tripMembers?.nights ? tripMembers?.nights : 'N/A',
    'Number of Travellers:': `${tripMembers?.adults} Adults, ${tripMembers?.children} Children, ${tripMembers?.infants} Infants`,
    'Travelling with children:': tripMembers?.infants + tripMembers?.children > 0 ? 'Yes' : 'No',
    // 'Age Group of Adults:': '18-30',
    'Is Budget flexible:': 'Taking the perfect trip is most important',
    'Type of travel:': 'Custom Trip Package'
    // 'Travclan Code:':
    //   tripMembers?.hotelRating === '5'
    //     ? travclanCodeFive
    //     : tripMembers?.hotelRating === '4'
    //     ? travclanCodeFour
    //     : tripMembers?.hotelRating === '3'
    //     ? travclanCode
    //     : 'N/A'
  };

  // const handlePrint = () => {
  //   const printContent = document.querySelector('.confirm-modal');
  //   const printWindow = window.open('', '_blank');
  //   const styles = document.head.querySelectorAll("style, link[rel='stylesheet']");

  //   printWindow.document.write('<html><head><title>Print</title>');
  //   styles.forEach((style) => printWindow.document.write(style.outerHTML));
  //   printWindow.document.write('</head><body>');
  //   printWindow.document.write(printContent.outerHTML);
  //   printWindow.document.write('</body></html>');

  //   printWindow.document.close();
  //   printWindow.focus();
  //   printWindow.print();
  //   printWindow.close();
  // };

  return (
    <Box
      className="Hiren"
      bgcolor="#f5f5f5"
      width="100%"
      height={
        isTravclanError && !quotationPrice && !downloadUrl
          ? '120vh'
          : !isTravclanError && quotationPrice === '' && downloadUrl === ''
          ? '60vh'
          : !isTravclanError && quotationPrice && downloadUrl
          ? '80vh'
          : '60vh'
      }
      display="grid"
      sx={{ placeItems: 'center' }}>
      <Box
        sx={{
          p: 2,
          bgcolor: !quotationPrice ? '#fff' : '#27306B',
          textAlign: 'center',
          boxShadow: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxHeight:
            isTravclanError && quotationPrice === undefined && downloadUrl === undefined
              ? '98vh'
              : '90vh',
          overflowY: 'auto'
        }}
        className="confirm-modal">
        <Box
          sx={{
            borderRadius: !quotationPrice ? '0px' : '5px',
            border: !quotationPrice ? 'none' : '1px solid #ffffff !important',
            p: !quotationPrice ? 0 : 2
          }}>
          {quotationPrice && (
            <Box textAlign="end" width="570px" height="18px">
              <img src={KesariArrowWhite} alt="Arrow icon" width="50px" height="40px" />
            </Box>
          )}
          <Typography
            fontFamily="gothamBold"
            fontSize={30}
            margin={0}
            color={!quotationPrice ? '#000000' : '#FFFFFF'}>
            Thank you for your enquiry!
          </Typography>
          {!quotationPrice && (
            <Typography fontFamily="gothamBook" fontSize={18}>
              We will get in touch with you shortly.
            </Typography>
          )}

          {quotationPrice && (
            <>
              <Box
                sx={{
                  backgroundColor: '#FFFFFF',
                  color: '#E41E25',
                  py: 1,
                  my: 1,
                  mx: 'auto',
                  borderRadius: 3,
                  width: '420px'
                }}>
                <Typography
                  my={1}
                  sx={{
                    fontFamily: 'gothamBold',
                    fontSize: '26px'
                  }}>
                  Total Cost: ₹ <span>{formatPrice(quotationPrice)}</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #FFFFFF !important',
                  p: 1,
                  my: 2,
                  mt: 3,
                  mx: 'auto',
                  borderRadius: 3,
                  width: '415px',
                  position: 'relative',
                  top: '5px'
                }}>
                <Typography
                  mt={-7.5}
                  // marginLeft={23.75}
                  color="#FFFFFF"
                  fontSize={16}
                  fontFamily="gothamBold"
                  backgroundColor="#27306B"
                  whiteSpace="nowrap"
                  position="absolute">
                  Here are your customized holiday details
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    position: 'relative',
                    top: '26px'
                  }}>
                  <Button
                    sx={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: 2,
                      color: '#E41E25',
                      fontWeight: 700,
                      fontFamily: 'gothamBold',
                      fontSize: '16px',
                      whiteSpace: 'nowrap',
                      minWidth: '190px',
                      height: '40px',
                      py: 2,
                      ':hover': {
                        bgcolor: '#0056b3',
                        color: '#fff'
                      }
                    }}
                    onClick={handlePreview}>
                    Preview
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: 2,
                      color: '#E41E25',
                      fontWeight: 700,
                      fontFamily: 'gothamBold',
                      fontSize: '16px',
                      whiteSpace: 'nowrap',
                      minWidth: '190px',
                      height: '40px',

                      py: 2,
                      ':hover': {
                        bgcolor: '#0056b3',
                        color: '#fff'
                      }
                    }}
                    onClick={handleDownload}
                    disabled={isLoader}>
                    Download Now
                    {isLoader && <CircularProgress size={20} sx={{ color: '#fff', ml: 1 }} />}
                  </Button>
                </Box>
              </Box>
              {/* {downloadUrl && (
                <Button
                  sx={{
                    bgcolor: '#007bff',
                    color: '#fff',
                    px: 4,
                    py: 1.5,
                    mt: 2,
                    fontFamily: 'gothamBold',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    ':hover': {
                      bgcolor: '#0056b3',
                      color: '#fff'
                    }
                  }}
                  variant="contained"
                  onClick={handleDownload}
                  disabled={isLoader}>
                  Download Here
                  {isLoader && <CircularProgress size={20} sx={{ color: '#fff' }} />}
                </Button>
              )} */}
            </>
          )}

          {/* <Typography fontFamily="gothamBook" fontSize={18} mt={1}>
          Trip Request ID:
        </Typography>
        <Typography fontFamily="gothamBook" fontSize={16} margin={0}>
          BSD2QWS1WSCX
        </Typography> */}

          {isTravclanError && (
            <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 400, overflowY: 'auto' }}>
              <Table>
                <TableBody>
                  {Object.entries(tripDetails).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        sx={{
                          borderBottom: 'none',
                          padding: '12px',
                          maxWidth: '141px',
                          wordWrap: 'break-word'
                        }}
                        component="th"
                        scope="row">
                        {key}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', padding: '12px' }}>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Button
            sx={{
              bgcolor: !quotationPrice ? '#ed9300' : '#5d5b8a',
              color: '#fff',
              px: 4,
              py: 1.5,
              mt: 5,
              width: '198px',
              fontFamily: 'gothamBold',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              borderRadius: 2,
              ':hover': {
                bgcolor: !quotationPrice ? '#ed9300' : '#5c5B89',
                color: '#fff'
              }
            }}
            variant="contained"
            onClick={() => (window.location.href = `${window.location.origin}/explore`)}>
            {/* onClick={handlePrint}> */}
            Explore More Holidays
          </Button>
          <Typography mt={2} sx={{ color: !quotationPrice ? '#000000' : '#FFFFFF' }}>
            Need help? Contact us:{' '}
            <Link
              href="mailto:holiday@kesariselect.in"
              sx={{ color: !quotationPrice ? '#000000' : '#FFFFFF', textDecoration: 'none' }}>
              holiday@kesariselect.in
            </Link>{' '}
            |{' '}
            <Typography
              component="a"
              href="tel:18002669080"
              sx={{ color: !quotationPrice ? '#000000' : '#FFFFFF', textDecoration: 'none' }}>
              1800 266 9080
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TripRequestConfirmation;
